import React from "react";
import Divider from "./Divider";
import Tag from "./Tags";

const SkillCard = ({ skillName, skillIcon, skillTags }) => {

  let splitString = skillName.split('\n');

  return (
    <div className="w-full mx-auto">
    {/* <div className="gradient-border-background-neutral-2 border-width-1 shadow-xl rounded-md transform transition-all duration-500 hover:scale-105 hover:shadow-2xl"> */}
    {/* <div className="text-center p-5 rounded-smd transition duration-500 hover:shadow-innerGlowSmall"> */}
    {/* <div className="icon-ui text-ui">
    {skillIcon}
    </div> */}
      <h2 className="text-lg mt-4 tracking-wide text-left text-transparent text-gradient-sans">{splitString[0]}</h2>
      <h2 className="text-lg tracking-wide text-left text-transparent text-gradient-sans">{splitString[1]}</h2>
    {/* </div> */}
    {/* </div> */}
    <Divider marginTop="mt-4" marginBottom="mb-4"  />
    <Tag tags={skillTags} gap={"gap-2"} />
    </div>
  );
};

export default SkillCard;
