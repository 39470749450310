import React, { useEffect } from "react";
import About from "./About";
import Projects from "./Projects";
import Skills from "./Skills";
import data from "../assets/data";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Header";
import Divider from "./Divider";
import LogoBanner from "./LogoBanner";

function Home() {
  useEffect(() => {
    AOS.init({
      // once: true,
    });
  });

  return (
    <div className="">
      {/* Header */}
      <div data-aos="fade-down" data-aos-duration="500" className="mx-auto">        
        <Header />
      </div>
      <div className="content-wrapper">
      {/* Profile */}
      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
        <Divider marginTop="mt-8" marginBottom="mb-8" />
        <About title={data.about.title} description={data.about.description} />
        <Divider marginTop="mt-8" marginBottom="mb-8" />
        <LogoBanner/>
        <Divider marginTop="mt-8" marginBottom="mb-8" />
        <Skills skills={data.skills} title={"Fähigkeiten"}/>
      </div>
      {/* Project */}
      <Divider marginTop="mt-8" marginBottom="mb-8" />
      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
        <Projects projects={data.projects} className="mb-24" />
      </div>
      <Divider marginTop="mt-8" marginBottom="mb-8" />
      </div>
    </div>
  );
}

export default Home;