import React from "react";
import  classnames from "classnames";

function Divider(props) {

    const classNames = classnames(
        "h-px",
        props.marginTop,
        props.marginBottom,
        props.dividerGradient,
        props.shadow,
        props.width,
        props.position
      );

    return (
        <div className={classNames} />
    );
}

export default Divider;