import React from "react";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, EffectCoverflow, Pagination, Scrollbar, A11y } from 'swiper';

function ImageSlider(props) {

  const images = props.images;

  return (
    <div className="">
      <Swiper
        // install Swiper modules
        id="swiper-replace"
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        loop={true}
        // effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        // coverflowEffect={{
        //   rotate: 10,
        //   stretch: 0,
        //   depth: 100,
        //   modifier: 1,
        //   slideShadows: false,
        // }}
        // pagination={{ clickable: true }}
        pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
        {images.map((images) => (
          <SwiperSlide key={images.id} className="py-8">
            <div className="m-auto">
              <div className="wrapper shadow-md overflow-hidden">
                <img
                  className="h-auto w-auto player"
                  src={images.url}
                  alt={images.alt}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>


  );
}

export default ImageSlider;