import React from "react";
import  classnames from "classnames";

function Image(props) {

  const gap = props.gap;
  const tags = props.tags;
  
  const classNames = classnames(
    "flex flex-wrap justify-start",
    gap,
  );

  return (
    <div className={classNames} >
          {tags.map((tag) => (
            <div className="text-ui px-4 py-1 interactive-element shadow-lg rounded-full" style={{ whiteSpace: 'nowrap' }} >{tag}</div>
          ))}
        </div>
  );
}

export default Image;