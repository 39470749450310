import React from "react";
import classnames from "classnames";
import Divider from "./Divider";

const TextField = ({ border, maxWidth, width, hasMarginTop, heading, description, link, linkText }) => {
  const classNames = classnames(
    maxWidth,
    width,
    hasMarginTop,
    "text-wrapper"
  );
  return (
    <div className={classNames} >
      {(hasMarginTop) ? (
        <Divider marginTop="mt-4" />
      ) : (
        null
      )}
      <h3 className="text-xl font-normal text-left text-gradient-sans leading-relaxed">
        {heading}
      </h3>
      <Divider marginTop="mt-4" />
      <p className="text-base font-light text-left text-body leading-relaxed inline-block" style={{ whiteSpace: 'pre-wrap' }} >
        {description}
      </p>
      <a className="text-base font-thin text-left text-body text-link leading-relaxed underline inline-block" href={link}>{linkText}</a>
    </div>
  );
}

export default TextField;