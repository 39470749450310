import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import VideoPlayer from "./VideoPlayer";
import TextField from "./TextField";
import ImageSlider from "./ImageSlider";
import ProjectHeader from "./ProjectHeader";
import Image from "./Image";
import data from "../assets/data";

const EcoGuide = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    AOS.init({
      // once: true,
    });
  });

  const project = data.projects[10];
  const images = project.images;
  const title = project.title;
  const text = project.text;
  const videoURL = project.videoURL;
  const companyLogo = project.companyLogo;
  const partnerLogo = project.partnerLogo;
  const tags = project.tags;

  return (
    <div>
      <div data-aos="fade-down" data-aos-duration="500">
        <ProjectHeader title={title} companyLogo={companyLogo} partnerLogo={partnerLogo} tags={tags} />       
      </div>
      <div data-aos="fade-up" data-aos-duration="800" data-aos-delay="400" className="content-wrapper">
         {/* Der EcoGuide */}
         <TextField hasMarginTop={true} heading={text[0].heading} description={text[0].body} />
        <ImageSlider images={images.slice(0, 2)} />

        {/* Forschungsansatz */}
        <TextField hasMarginTop={true} heading={text[1].heading} description={text[1].body} />

        {/* GWAP */}
        <TextField hasMarginTop={true} heading={text[2].heading} description={text[2].body} />
        <Image image={images[2]} />

        {/* Spielelemente */}
        <TextField hasMarginTop={true} heading={text[3].heading} description={text[3].body} />
        <ImageSlider images={images.slice(3, 5)} />

        {/* Bedienkonzept */}
        <TextField hasMarginTop={true} heading={text[4].heading} description={text[4].body} />
        <Image image={images[5]} />

        {/* Onboard-Feedback */}
        <TextField hasMarginTop={true} heading={text[5].heading} description={text[5].body} />
        <Image image={images[6]} />

        {/* Szenario 1 */}
        <TextField hasMarginTop={true} heading={text[6].heading} description={text[6].body} />
        <Image image={images[7]} />
        {/* <VideoPlayer images={images[0]} videoURL={videoURL[0]} /> */}

        {/* Szenario 2 */}
        <TextField hasMarginTop={true} heading={text[7].heading} description={text[7].body} />
        <Image image={images[8]} />
        {/* <VideoPlayer images={images[1]} videoURL={videoURL[1]} /> */}

        {/* Szenario 3 */}
        <TextField hasMarginTop={true} heading={text[8].heading} description={text[8].body} />
        <Image image={images[9]} />
        {/* <VideoPlayer images={images[14]} videoURL={videoURL[2]} /> */}

        {/* Studienaufbau & Ergebnisse */}
        <TextField hasMarginTop={true} heading={text[9].heading} description={text[9].body} />
        <ImageSlider images={images.slice(10, 12)} />

        {/* Nutzerakzeptanz */}
        <TextField hasMarginTop={true} heading={text[10].heading} description={text[10].body} />
        <ImageSlider images={images.slice(12, 14)} />
      </div>
    </div>
  );
}

export default EcoGuide;