import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import TextField from "./TextField";
import ImageSlider from "./ImageSlider";
import ProjectHeader from "./ProjectHeader";
import Image from "./Image";
import data from "../assets/data";
import VideoPlayer from "./VideoPlayer";

const FlexCar = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    AOS.init({
      // once: true,
    });
  });

  const project = data.projects[2];
  const images = project.images;
  const title = project.title;
  const text = project.text;
  const companyLogo = project.companyLogo;
  const partnerLogo = project.partnerLogo;
  const tags = project.tags;
  const videoURL = project.videoURL;

  return (
    <div>
      <div data-aos="fade-down" data-aos-duration="500">
        <ProjectHeader title={title} companyLogo={companyLogo} partnerLogo={partnerLogo} tags={tags} />
      </div>
      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" className="content-wrapper">
        <Image image={images[0]} />

        {/* Projektziel */}
        <TextField hasMarginTop={true} heading={text[0].heading} description={text[0].body}  />

        {/* Interaktionskonzept */}
        <TextField hasMarginTop={true} heading={text[1].heading} description={text[1].body}  />

        <TextField hasMarginTop={true} description={text[2].body}  />
        <Image image={images[1]} />

        <TextField hasMarginTop={true} description={text[3].body}  />
        <Image image={images[2]} />

        <TextField hasMarginTop={true} description={text[4].body}  />
        <Image image={images[3]} />

        <TextField hasMarginTop={true} description={text[5].body}  />
        <ImageSlider images={images.slice(8, 10)} />

        <TextField hasMarginTop={true} description={text[6].body}  />
        <div className="md:grid md:grid-cols-2 md:gap-8">
          <Image image={images[4]} />
          <Image image={images[5]} />
        </div>
        <div className="md:grid md:grid-cols-2 md:gap-8">
        <Image image={images[6]} />
          <Image image={images[7]} />
        </div>

        {/* Studienablauf */}
        <TextField hasMarginTop={true} heading={text[7].heading} description={text[7].body}  />
        
        {/* Ergebnisse */}
        <TextField hasMarginTop={true} heading={text[8].heading} description={text[8].body}  />
        <VideoPlayer images={images[10]} videoURL={videoURL} />
      </div>
    </div>
  );
}

export default FlexCar;