import React from "react";
import data from "../assets/data";
import Divider from "./Divider";

function LogoBanner() {
  return (
    <div className="text-ui">
      <h3 className="text-ui text-left uppercase">Partners I have worked with</h3>
      <Divider marginTop="mt-2" marginBottom="mb-2" />
      <div className="mx-auto grid grid-cols-2 lg:grid-cols-6 sm:grid-cols-4 gap-4">

        <div className=" p-5"><img
          className="mx-auto justify-self-end object-scale-down h-10"
          src="https://eliasmerzhaeuser.com/content/logos/bw/mercedes-benz1.svg"
          alt="Mercedes-Benz Logo"
        /></div>
        <div className=" p-5"><img
          className="mx-auto justify-self-end object-scale-down h-10"
          src="https://eliasmerzhaeuser.com/content/logos/bw/porsche1.svg"
          alt="Porsche Logo"
        /></div>
        <div className=" p-5"><img
          className="mx-auto justify-self-end object-scale-down h-10"
          src="https://eliasmerzhaeuser.com/content/logos/bw/fraunhofer_ipa.svg"
          alt="Fraunhofer IPA Logo"
        /></div>
        <div className=" p-5"><img
          className="mx-auto justify-self-end object-scale-down h-10"
          src="https://eliasmerzhaeuser.com/content/logos/bw/plus101.svg"
          alt="Plus10 Logo"
        /></div>
        <div className=" p-5"><img
          className="mx-auto justify-self-end object-scale-down h-10"
          src="https://eliasmerzhaeuser.com/content/logos/bw/staatsgalerie.svg"
          alt="Staatsgalerie Logo"
        /></div>
        <div className=" p-5"><img
          className="mx-auto justify-self-end object-scale-down h-10"
          src="https://eliasmerzhaeuser.com/content/logos/bw/vvs.svg"
          alt="VVS Logo"
        /></div>
      </div>
    </div>
  );
}

export default LogoBanner;