import React, { useEffect } from "react";
import Card from "./Card";
import data from "../assets/data";
import "aos/dist/aos.css";


function Header() {

  // const documentHeight = () => {
  //   const doc = document.documentElement
  //   doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
  //  }
  //  window.addEventListener("resize", documentHeight)
  //  documentHeight()

  // Setting static view height for mobile browsers
  // @ToDo: detect devices, browser and orientation change
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // useEffect(() => {
  //   // We listen to the resize event
  //   window.addEventListener('resize', handleResize);
  // });

  return (
    <div className="flex justify-center items-end pb-24 vh-100-mobile">

      <img
        className="pattern-bg-top object-cover w-full h-full"
        src="https://eliasmerzhaeuser.com/content/bg-photo.jpg"
        alt="Background Photo"
      />
      {/* <Card name={data.name} title={data.title} social={data.social} /> */}
      
        <div className="bg-[#070707] px-6 py-4 border-1 border-[#030303] shadow-innerShadowInput rounded-full">
          <p class="line-1 anim-typewriter text-neutral-300 font-light text-base md:text-2xl">Willkommen zu meinem Portfolio!</p>
        </div>
      
    </div>
  );
}

export default Header;