import React, { useState, useEffect } from "react";
import { FaEnvelope } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXingSquare } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import profile from "../images/profile_photo.png";
import Divider from "./Divider";
import { Link } from "react-router-dom";

const name = [
  "Elias Merzhäuser",
  <br />
];

const title = [
  "UX/UI Designer & Developer"
];

function Topbar({ social: { linkedin, xing, email } }) {

  const [isDesktop, setDesktop] = useState(window.innerWidth > 800);

  const [isNavbarVisible, setNavbar] = useState(false);

  if (isDesktop && isNavbarVisible) {
    setNavbar(!isNavbarVisible);
  }

  const updateMedia = () => {
    setDesktop(window.innerWidth > 800);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="fixed top-0 z-10 w-full bg-black shadow-xl text-neutral-300 font-light overflow-hidden">
      <div className="flex items-center justify-between">

        {/* Home Button */}
        <Link to="/" >
          <div className="flex items-center px-4 py-4">
            <img
              className="w-12 h-12 mx-auto shadow-md mr-4"
              src="https://eliasmerzhaeuser.com/content/logos/em_logo1.png"
              alt="EM logo"
            />
            <div className="flex items-center">
              <div className="flex flex-col">
                <p className="">{name}</p>
                <p className="text-ui text-sm">{title}</p>
              </div>
            </div>
          </div>
        </Link>
        <div>

          {isDesktop ? (
            <ul className="flex flex-col gap-2 lg:flex-row lg:items-center lg:gap-6 pr-4">
              <div className="flex items-center justify-center">
                <a
                  className="pr-4 text-base text-ui hover:text-neutral-300 transition-colors duration-300"
                  href={"mailto:" + email}
                >
                  Email
                  <span class="sr-only">Email</span>
                </a>
                <a
                  className="px-4 text-base text-ui hover:text-neutral-300 transition-colors duration-300"
                  href={linkedin}
                >
                  Linkedin
                  <span class="sr-only">Linkedin</span>
                </a>
                <a
                  className="pl-4 text-base text-ui hover:text-neutral-300 transition-colors duration-300"
                  href={xing}
                >
                  Xing
                  <span class="sr-only">Xing</span>
                </a>
              </div>
            </ul>
          ) : (
            <button
              className="px-4 py-8 text-xl text-ui hover:text-neutral-300 transition-colors duration-300"
              onClick={() => setNavbar(!isNavbarVisible)}
            >
              <AiOutlineMenu />
            </button>
          )}
        </div>
      </div>
      <Divider dividerGradient="divider-gradient" />

      {(isDesktop) ? ("") : (
        <ul className={`w-full flex flex-col all 1s ease-out ${isNavbarVisible ? "block" : "hidden"}`}>
          <li className="flex items-end justify-end">
            <a
              className="w-full padding-list text-base text-ui text-end hover:text-neutral-300 transition-colors duration-300"
              href={"mailto:" + email}
            >
              Email
              <span class="sr-only">Email</span>
            </a>
          </li>
          <Divider dividerGradient="divider-gradient" />
          <li className="flex justify-end">
            <a
              className="padding-list text-base text-ui w-full text-end hover:text-neutral-300 transition-colors duration-300"
              href={linkedin}
            >
              Linkedin
              <span class="sr-only">Linkedin</span>
            </a>
          </li>
          <Divider dividerGradient="divider-gradient" />
          <li className="flex justify-end">
            <a
              className="padding-list text-base text-ui  w-full text-end hover:text-neutral-300 transition-colors duration-300"
              href={xing}
            >
              Xing
              <span class="sr-only">Xing</span>
            </a>
          </li>
          <Divider dividerGradient="divider-gradient" />
        </ul>
      )}
    </div>
  );
}

export default Topbar;

