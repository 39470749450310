import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import TextField from "./TextField";
import ImageSlider from "./ImageSlider";
import ProjectHeader from "./ProjectHeader";
import data from "../assets/data";

const Drinq = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    AOS.init({
      // once: true,
    });
  });

  const project = data.projects[7];
  const images = project.images;
  const title = project.title;
  const text = project.text;
  const companyLogo = project.companyLogo;
  const tags = project.tags;

  return (
    <div>
      <div data-aos="fade-down" data-aos-duration="500">
        <ProjectHeader title={title} companyLogo={companyLogo}  tags={tags}/> 
      </div>
      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" className="content-wrapper">

        {/* Health & Gamification */}
        <TextField hasMarginTop={true} heading={text[0].heading} description={text[0].body} />

        {/* Apple Health-Kit */}
        <TextField hasMarginTop={true} heading={text[1].heading} description={text[1].body} />
 
        {/* User Interface */}
        <TextField hasMarginTop={true} heading={text[2].heading} description={text[2].body} />
        <ImageSlider images={images.slice(1, 3)} />
      </div>
    </div>
  );
}

export default Drinq;