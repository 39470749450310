import React from "react";
import { Link } from "react-router-dom";
import Divider from "./Divider";

function Footer() {
  return (
    <div className="text-center w-full bg-[#0a0a0a] shadowXLReverse">
      <Divider marginTop="mt-8" marginBottom="mb-8" dividerGradient="divider-gradient" shadow="shadow-sm" />
      <div className="flex items-center justify-center mb-4">
        <img
          className="w-12 h-12 object-scale-down object-left shadow-md"
          src="https://eliasmerzhaeuser.com/content/logos/em_logo1.png"
          alt="EM logo"
        />
      </div>
      <p className="text-gradient-sans">
        Copyright <span role="img" aria-label="Copyright Sign">©️</span> 2023 by Elias Merzhäuser
      </p>
      <p className="text-ui mb-4">
        Stuttgart, Germany
      </p>
      <Link to="/impressum">
        <a className="text-left text-link leading-relaxed underline inline-block" href="/">Impressum</a>
      </Link>
      <Divider marginBottom="mt-8" />
    </div>
  )
}

export default Footer;