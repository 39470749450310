import React from "react";

function Button(props) {
    return (
        <button className="h-10 interactive-element text-ui tracking-normal py-2 px-4 rounded-sm inline-flex items-center hover:text-white hover:bg-[#101010] active:scale-95 active:outline-none focus:outline-none">
          {props.children}
          {props.buttonText}
      </button>
    );
}

export default Button;