import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import TextField from "./TextField";
import ProjectHeader from "./ProjectHeader";
import Image from "./Image";
import data from "../assets/data";

const PNDLR = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    AOS.init({
      // once: true,
    });
  });

  const project = data.projects[8];
  const images = project.images;
  const title = project.title;
  const text = project.text;
  const companyLogo = project.companyLogo;
  const tags = project.tags;

  return (
    <div>
      <div data-aos="fade-down" data-aos-duration="500">
        <ProjectHeader title={title} companyLogo={companyLogo} tags={tags} />
      </div>
      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" className="content-wrapper">

        {/* Mobil am Campus */}
        <TextField hasMarginTop={true} heading={text[0].heading} description={text[0].body} />
        <Image colSpan="md:col-span-2" image={images[1]} />

        {/* Nächste Verbingung */}
        <TextField hasMarginTop={true} heading={text[1].heading} description={text[1].body} />
      </div>
    </div>
  );
}

export default PNDLR;