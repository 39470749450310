import React from "react";

function PlayButton(props) {
    return (
        <button className="w-16 h-16 grid items-center justify-items-center interactive-element text-ui tracking-normal rounded-full hover:text-white hover:bg-[#101010] active:scale-95 active:outline-none focus:outline-none">
          {props.children}
      </button>
    );
}


export default PlayButton;