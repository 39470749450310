import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import Divider from "./Divider";
import Button from "./Button";


const ProjectCard = ({ project: { title, subtitle, companyLogo, partnerLogo, description, route, images, videoURL, status, isImprint } }) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 800);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 800);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Link to={route} state={{ projectImages: images, projectTitle: title, companyLogo: companyLogo, videoURL: videoURL, isImprint: isImprint }} className="flex-1">
    <div class="group w-full md:max-w-2xl mx-auto">
      {/* <div className="gradient-border-background-neutral border-width-1 rounded-md ">
      <div className="border-1 border-black rounded-smd shadow-reflex"> */}
      <div>
        {/* Preview */}
        <div className="mx-auto overflow-hidden group relative">
          {isDesktop ? (
            <img
              className="h-48 w-full object-cover object-center transition-all duration-1000 ease-in-out transform group-hover:scale-110"
              src={images[0].url}
              alt="Project preview"
            />
          ) : (
            <img
              className="h-48 w-full object-cover object-center"
              src={images[0].url}
              alt="Porject preview"
            />
          )}
          <div className="absolute top-2 left-2 font-thin text-ui text-xs rounded-sm shadow-md">
            <div className="px-2 py-1 rounded-sm flex items-center interactive-element">
            {/* {(status === "FINISHED") ? (<FaCheck className="icon-ui mr-2" />) : (<FaCircle className="icon-ui mr-2" />)} */}
              {status}
            </div>
          </div>
          {/* {partnerLogo ? (
            <div className="absolute top-2 right-2">
              <img
                className="justify-self-start object-scale-down h-10 w-10 rounded-sm mr-2"
                src={partnerLogo}
                alt="company logo"
              />
            </div>
          ) :
            ("")} */}
        </div>
        {/* Logo, Name & Button */}
        <div class="mt-4 flex justify-between items-center">
          <div className="flex justify-self-start items-center">
            <div className="">
              {/* <img
                className="justify-self-start object-scale-down h-10 w-10 rounded-sm mr-2"
                src={companyLogo}
                alt="company logo"
              /> */}
            </div>
            <div className="">
              <h1 className="text-xl text-left font-normal text-gradient-sans">
                {title}
              </h1>
              <h3 className="text-lg text-left text-ui">
                {subtitle}
              </h3>
            </div>
          </div>
          <div className="justify-self-end">
            
              {/* <Button>
                <FaChevronRight className="icon-ui" />
              </Button> */}
           
          </div>
        </div>

        {/* Description */}
        <p className="mt-4 font-light text-body text-left line-clamp-3">
          {description}
        </p>
      </div>
    
    </div>
    
    </Link>
  );
};

export default ProjectCard;
