import React from "react";
import "./App.css";
import Home from "./partials/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BeginnerDriverMode from "./partials/BeginnerDriverMode";
import FlexCar from "./partials/FlexCar";
import EcoGuide from "./partials/EcoGuide";
import APA from "./partials/APA";
import Plus10 from "./partials/Plus10";
import Xplore from "./partials/Xplore"
import Drinq from "./partials/Drinq";
import PNDLR from "./partials/PNDLR";
import DisposalApp from "./partials/DisposalApp";
import Imprint from "./partials/Imprint";
import VVSChatbot from "./partials/VVSChatbot";
import Tetris from "./partials/Tetris";
import Footer from "./partials/Footer";

import Topbar from "./partials/Topbar";
import data from "./assets/data";
import Quizzit from "./partials/Quizzit";

function App() {
  return (  
      <BrowserRouter>
      <Topbar social={data.social} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/beginnerDriverMode" element={<BeginnerDriverMode />} />
          <Route exact path="/flexCar" element={<FlexCar />} />
          <Route exact path="/ecoGuide" element={<EcoGuide />} />
          <Route exact path="/apaApp" element={<APA />} />
          <Route exact path="/plus10" element={<Plus10 />} />
          <Route exact path="/xplore" element={<Xplore />} />
          <Route exact path="/drinq" element={<Drinq />} />
          <Route exact path="/pndlr" element={<PNDLR />} />
          <Route exact path="/vvsChatbot" element={<VVSChatbot />} />
          <Route exact path="/disposalApp" element={<DisposalApp />} />
          <Route exact path="/tetris" element={<Tetris />} />
          <Route exact path="/quizzit" element={<Quizzit />} />
          <Route exact path="/impressum" element={<Imprint />} />
        </Routes>
        <Footer />
      </BrowserRouter>
  );
}
export default App;