import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import TextField from "./TextField";
import ImageSlider from "./ImageSlider";
import ProjectHeader from "./ProjectHeader";
import Image from "./Image";
import data from "../assets/data";
import Divider from "./Divider";

const BeginnerDriverMode = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    AOS.init({
      // once: true,
    });
  });

  const project = data.projects[0];
  const images = project.images;
  const title = project.title;
  const text = project.text;
  const companyLogo = project.companyLogo;
  const tags = project.tags;

  return (
    <div>
      <div data-aos="fade-down" data-aos-duration="500">
        <ProjectHeader title={title} companyLogo={companyLogo} tags={tags} />
      </div>
      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" className="content-wrapper">
      <ImageSlider images={images.slice(0, 4)} />
      <TextField hasMarginTop={true} heading={text[1].heading} description={text[1].body} link={"https://shop.mercedes-benz.com/de-de/connect/pdp/fahranfaenger--und-parkdienstmodus/547"} linkText={"Fahranfängermodus Mercedes-Benz Shop"}  />

        {/* Meine Rolle */}
        <TextField hasMarginTop={true} heading={text[0].heading} description={text[0].body}  />

        {/* User Needs & Screen Flow */}
        <TextField hasMarginTop={true} heading={text[2].heading} description={text[2].body}  />
        {/* <Image image={images[4]} /> */}
        <div className="md:grid md:grid-cols-2 md:gap-8">
          <Image image={images[images.length - 2]} />
          <Image image={images[images.length - 1]} />
        </div>
        <TextField hasMarginTop={true} heading={text[3].heading} description={text[3].body}  />
        <Image image={images[5]} />

        {/* Icons & Graphics*/}
        <TextField hasMarginTop={true} heading={text[4].heading} description={text[4].body}  />
        <div className="md:grid md:grid-cols-2 md:gap-8">
          <Image image={images[6]} />
          <Image image={images[7]} />
        </div>
        <Image image={images[8]} />
      </div>
    </div>
  );
}

export default BeginnerDriverMode;