import React from "react";
import Divider from "./Divider";
import ProjectCard from "./ProjectCard";

const Projects = ({ projects }) => {
    return (
        <div className="">
            <h1 className="mb-8 md:leading-normal text-3xl md:text-4xl text-left text-gradient">Projekte</h1>
            <div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-3  sm:grid-cols-2">            
            {projects.map((project) => (
                <ProjectCard project={project} />
            ))}
            </div>
        </div>
    );
};

export default Projects;