import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import VideoPlayer from "./VideoPlayer";
import TextField from "./TextField";
import ImageSlider from "./ImageSlider";
import ProjectHeader from "./ProjectHeader";
import Image from "./Image";
import data from "../assets/data";
import Tag from "../partials/Tags";
import Wrapper from "./Wrapper";

const Xplore = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    AOS.init({
      // once: true,
    });
  });

  const project = data.projects[6];
  const images = project.images;
  const title = project.title;
  const text = project.text;
  const videoURL = project.videoURL;
  const companyLogo = project.companyLogo;
  const partnerLogo = project.partnerLogo;
  const tags = project.tags;

  return (
    <div className="">
      <div data-aos="fade-down" data-aos-duration="500">
        <ProjectHeader title={title} companyLogo={companyLogo} partnerLogo={partnerLogo} tags={tags} />
      </div>
      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" className="content-wrapper">
        <ImageSlider images={images.slice(1, 6)} />

        {/* Forschungsziel */}
        <TextField hasMarginTop={true} heading={text[0].heading} description={text[0].body} />
        <VideoPlayer images={images[0]} videoURL={videoURL[0]} />

        {/* Interaktion über Social Cues */}
        <TextField hasMarginTop={true} heading={text[1].heading} description={text[1].body} />
        <Image image={images[8]}/>

        {/* Lichtimpulse & Feedback-Layer */}
        <TextField hasMarginTop={true} heading={text[2].heading} description={text[2].body} />
        <ImageSlider images={images.slice(6, 8)} />

        {/* User Research */}
        <TextField hasMarginTop={true} heading={text[6].heading} description={text[6].body} />
        <div className="md:grid md:grid-cols-3 md:gap-8">
          <Image image={images[20]} />
          <Image image={images[21]} />
          <Image image={images[22]} />
        </div>

        {/* Szenario 1*/}
        <TextField hasMarginTop={true} heading={text[3].heading} description={text[3].body} />
        <ImageSlider images={images.slice(9, 14)} />

        {/* Szenario 2 */}
        <TextField hasMarginTop={true} heading={text[4].heading} description={text[4].body} />
        <ImageSlider images={images.slice(14, 19)} />

        {/* User Test */}
        <TextField hasMarginTop={true} heading={text[5].heading} description={text[5].body} />
        <Image image={images[19]}  />
      </div>
    </div>

  );
}

export default Xplore;