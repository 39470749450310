import React from "react";
import { FaPalette, FaUniversalAccess, FaFileCode } from "react-icons/fa";

const data = {
  name: "Elias Merzhäuser",
  title: "UX/UI Designer/\nFront-End Developer",
  logo: "https://eliasmerzhaeuser.com/content/logos/em_logo.png",
  social: {
    linkedin: "https://www.linkedin.com/in/eliasmerzhäuser",
    xing: "https://www.xing.com/profile/Elias_Merzhaeuser",
    email: "info@eliasmerzhaeuser.com",
  },
  about: {
    title: "Profil",
    description:
      "Mein Name ist Elias Merzhäuser und ich beschäftige mich intensiv mit den Themen UX/UI Design und Usability. Derzeit bin ich Werkstudent im Bereich Research & Development bei der Mercedes-Benz AG. Ich habe eine Leidenschaft für präzises, funktionales Design und eine kohärente Designsprache. Außerdem erstelle ich gerne eigene Apps und experimentiere mit Interaktionen für Anwendungen, die Spaß machen. Hoffentlich gefällt dir mein Portfolio! Kontaktiere mich gerne, wenn du Fragen oder ein Anliegen hast.",
  },
  skills: [
    {
      skillName: "UX/UI\nDesign",
      skillIcon: <FaPalette className="text-2xl mx-auto inline-block" />,
      tags: [
        "Design Thinking",
        "UX Design",
        "UI Design",
        "Interaction Design",
        "Prototyping",
        "Visual Design",
        "Print Design"
      ],
    },
    {
      skillName: "Usability\nEngineering",
      skillIcon: <FaUniversalAccess className="text-2xl mx-auto inline-block" />,
      tags: [
        "Usability Testing",
        "Accessability Testing",
        "User Research",
        "Personas",
        "User Interviews",
        "Contextual Inquiry",
        "Heuristic Evaluation",
        "Cognitive Walkthrough"
      ],
    },
    {
      skillName: "Front-End\nDevelopment",
      skillIcon: <FaFileCode className="text-2xl mx-auto inline-block" />,
      tags: [
        "App Development",
        "Android (Java)",
        "iOS (Swift)",
        "Unity (C#)",
        "Web Development",
        "HTML",
        "CSS",
        "Java-Script",
        "React"
      ],
    },
    {
      skillName: "Sprachen/\nFremdsprachen",
      skillIcon: <FaFileCode className="text-2xl mx-auto inline-block" />,
      tags: [
        "Deutsch (Fließend)",
        "Englisch (C1)",
        "Französisch (B1)"
      ],
    },
  ],
  projects: [
    {
      title: "Fahranfängermodus",
      subtitle: "Mercedes-Benz",
      companyLogo: "https://eliasmerzhaeuser.com/content/logos/mercedes-benz_logo.png",
      description: "Fahrmodi mit reduziertem Beschleunigungsverhalten: Der Fahranfängermodus hilft beim Einstieg in das Fahren mit elektrischem Antrieb, indem die Leistung des Fahrzeugs so angepasst wird, dass eine Höchstgeschwindigkeit von 120 km/h erreicht werden kann.",
      tags: [
        "UI Concept",
        "UX Design",
        "Visual Design",
        "Usability Testing",
        "Low-Fidelity Prototyping",
        "High-Fidelity Prototyping"
      ],
      route: "/beginnerDriverMode",
      link: "",
      images: [
        {
          id: "1",
          alt: "Home Screen",
          url: "https://eliasmerzhaeuser.com/content/beginnerDriverMode/home.jpeg",  // require("../images/beginner_driver_mode.jpeg")
        },
        {
          id: "4",
          alt: "Wizard 1",
          url: "https://eliasmerzhaeuser.com/content/beginnerDriverMode/wizard_1.jpg", // require("../images/beginner_driver_mode_wizard.jpg")
        },
        {
          id: "5",
          alt: "Wizard 2",
          url: "https://eliasmerzhaeuser.com/content/beginnerDriverMode/wizard_2.jpg", //require("../images/beginner_driver_mode_wizard_2.jpg")
        },
        {
          id: "6",
          alt: "Wizard 3",
          url: "https://eliasmerzhaeuser.com/content/beginnerDriverMode/wizard_3.jpg", // require("../images/beginner_driver_mode_wizard_3.jpg")
        },
        {
          id: "1",
          alt: "User Needs",
          url: "https://eliasmerzhaeuser.com/content/beginnerDriverMode/user_needs.png",  // require("../images/bdm_user_needs.png")
        },
        {
          id: "2",
          alt: "Screen Flow",
          url: "https://eliasmerzhaeuser.com/content/beginnerDriverMode/screen_flow.png",  // require("../images/bdm_screen_flow.png")
        },
        {
          id: "7",
          alt: "UI-Icons @group-media.mercedes-benz.com",
          url: "https://eliasmerzhaeuser.com/content/beginnerDriverMode/icons.jpeg",  // require("../images/bdm_icons.jpeg")
        },

        {
          id: "8",
          alt: "Wizard Grafiken @group-media.mercedes-benz.com",
          url: "https://eliasmerzhaeuser.com/content/beginnerDriverMode/graphics.jpg",
        },
        {
          id: "9",
          alt: "BDM & VSM App-Icons shop.mercedes-benz.com",
          url: "https://eliasmerzhaeuser.com/content/beginnerDriverMode/icons_combined_v2.png",
        },
        {
          id: "10",
          alt: "Anja",
          url: "https://eliasmerzhaeuser.com/content/beginnerDriverMode/Anja.png",
        },
        {
          id: "11",
          alt: "Tom",
          url: "https://eliasmerzhaeuser.com/content/beginnerDriverMode/Tom.png",
        },
      ],
      videoURL: ["https://www.youtube.com/watch?v=ZZKwxV9gWs4"],
      status: "Live",
      text: [
        {
          heading: "Meine Rolle",
          body: "Das RD/MXS-Team entwickelt On-Demand-Anwendungen, die im Mercedes me store erhältlich sind. Ich erstellte und optimierte das UX/UI Design verschiedener Anwendungen in den Bereichen Entertainment und Fahrerassistenz. Zwei davon sind der nahezu identische Beginner Driver Mode & Valet Service Mode. Alle Anwendungen sind in den ganzheitlichen Designansatz von MBUX integriert. Zu meinen Aufgaben gehörte auch der UI-Konzeptionsprozess und die Umsetzung von Assets wie Icons, Grafiken und Animationen. Darüber hinaus unterstützte ich die Entwickler bei der Implementierung von Übersetzungen in der GUI Software.",
        },
        {
          heading: "Macht Beschleunigung leichter beherrschbar",
          body: "Bei batterieelektrischen Fahrzeugen steht – anders als bei Fahrzeugen mit konventionellem Antrieb – das Drehmoment ab Drehzahl 0 zur Verfügung. Dies kann bei Neueinsteigern zunächst eine Umgewöhnung erfordern, welche der Fahranfängermodus erleichtern kann. Eine sanftere Beschleunigung ermöglicht ein entspanntes Fahrgefühl bei gewohnt komfortablem Fahrverhalten. Zur Unterstützung von Fahranfängern ist die Leistung Ihres des Fahrzeugs angepasst, sodass grundsätzlich eine Höchstgeschwindigkeit von 120 km/h erreicht werden kann. Das Stabilitätsprogramm ESP bleibt dauerhaft eingeschaltet, damit es den Fahrer jederzeit unterstützen kann. Sportliche Fahrprogramme stehen bei aktiviertem Fahranfängermodus nicht zur Verfügung. Der Fahranfängermodus kann über die Mercedes me App an- und abgewählt werden. Das Berechtigungskonzept verhindert, dass der Fahranfängermodus durch einen anderen Nutzer abgewählt werden kann.",
        },
        {
          heading: "User Needs",
          body: "Es gibt für den Fahranfängermodus hauptsächlich zwei Zielgruppen. Die Fahrzeugbesitzer, welche in der Regel fahrerfahren sind und die Fahranfänger, welche die Fahrzeuge seltener fahren. Die hier stark zusammengefassten Ergebnisse repräsentieren die Grundbedürfnisse von Eltern und Kindern in diesem Zusammenhang. (Die Darstellungen basieren nicht auf internen oder vertraulichen Daten, sondern auf öffentlichen Informationen und haben lediglich erklärenden Charakter.)",
        },
        {
          heading: "Screen Flow",
          body: "Die Applikation hat drei Interaktionspunkte, die Mercedes me Mobile-App, den Homescreen und einen Onboarding Wizard. Über die Mobile-App können Berechtigungen verwaltet werden und eine sie dient als eine Fernsteuerung. Der Homescreen dient hauptsächlich der Aktivierung/Deaktivierung des Modus. Über den Wizard können Information zur Funktion abgerufen werden. Hier dargestellt ist eine stark vereinfachte Übersicht des Screen Flows.",
        },
        {
          heading: "Icons und Grafiken",
          body: "Die Icons und die Grafiken im UI habe ich in nach den MBUX Design-Styleguide angefertigt. Mittels der Darstellungen sollen die Funktionsweisen der Anwendung verdeutlicht werden. Außerdem habe ich dabei geholfen, dass die UI Texte in allen 36 Sprachen des MBUX Systems angezeigt werden."
        },
      ],
    },
    {
      title: "Tetris",
      subtitle: "Mercedes-Benz & Tetris",
      companyLogo: "https://eliasmerzhaeuser.com/content/logos/mercedes-benz_logo.png",
      partnerLogo: "https://eliasmerzhaeuser.com/content/logos/tetris_logo.png",
      description: "Für das MBUX-System arbeitete ich an der Konzeption und dem Design des Tetris Spiels. Die Einhaltung des vorgegebenen Styleguides der Tetris Company und die Erstellung einer individuellen User Experience, die auch für die Bedienung im Fahrzeug funktional und ansprechend ist waren die wesentlichen Herausforderungen des Projekts.",
      tags: [
        "UI Concept",
        "UX Design",
        "Visual Design",
        "Usability Testing",
        "Front-End Development",
        "QML",
      ],
      route: "/tetris",
      link: "",
      images: [
        {
          id: "1",
          alt: "Preview",
          url: "https://eliasmerzhaeuser.com/content/tetris/preview_v2.jpg",
        },
        {
          id: "1",
          alt: "UI Design",
          url: "https://eliasmerzhaeuser.com/content/tetris/Launchscreen.jpg",
        },
        {
          id: "2",
          alt: "Spielsteuerung",
          url: "https://eliasmerzhaeuser.com/content/tetris/Basescreen.jpg",
        },
        {
          id: "3",
          alt: "Move",
          url: "https://eliasmerzhaeuser.com/content/tetris/Move.jpg",
        },
        {
          id: "4",
          alt: "Rotate",
          url: "https://eliasmerzhaeuser.com/content/tetris/Rotate.jpg",
        },
        {
          id: "5",
          alt: "Soft_Drop",
          url: "https://eliasmerzhaeuser.com/content/tetris/Soft_Drop.jpg",
        },
        {
          id: "6",
          alt: "Hard_Drop",
          url: "https://eliasmerzhaeuser.com/content/tetris/Hard_Drop.jpg",
        },
        {
          id: "7",
          alt: "Hold",
          url: "https://eliasmerzhaeuser.com/content/tetris/Hold.jpg",
        },
        {
          id: "8",
          alt: "Video Preview",
          url: "https://eliasmerzhaeuser.com/content/tetris/video_preview.png",
        }
      ],
      videoURL: [""],
      status: "Live",
      text: [
        {
          heading: "UI Design",
          body: "Die Herausforderung bei der Konzeption und dem Design des Tetris Spiels für MBUX war die Einhaltung des vorgegebenen Styleguides der Tetris Company und die Erstellung einer individuellen User Experience, die auch für die Bedienung im Fahrzeug funktional und ansprechend ist. Dabei lag der Fokus visuell auf einer futuristischen Interpretation des Designs und einer entsprechenden Gestaltung der UI Elemente.\n\nIch habe das Produktlogo erstellt, welches auch als Icon im App Bucket dient und das UI Konzept, sowie das UI Design inklusive der Assets, wie Hintergrundbilder und Animationen, sowie die funktionale Umsetzung von UI Elemente in QML. Allerdings wurde das Konzept bisher nur teilweise so umgesetzt, wie es hier zu sehen ist.",
        },
        {
          heading: "Spielsteuerung",
          body: "Tetris lässt sich über mehrere Eingabemethoden bedienen. Touch Control beschreibt die Steuerung über den Touchscreen der verschiedenen Displays im Fahrzeug (HU/CDD) mit Touch Gesten. Andererseits können Nutzer auch das Fingerpad am Lenkrad für die Bewegung der Spielsteine (Tetriminos) verwenden.",
        },
        {
          heading: "Onboarding Wizard",
          body: "Der Onboarding Wizard soll die verschiedenen Möglichkeiten der Spielsteuerung erklären, insbesondere auch die Steuerung der über das Fingerpad. Die Darstellungen der Hilfestellungen wurden von mir als Animationen konzipiert und umgesetzt.",
        }
      ]
    },
    {
      title: "FlexCar",
      subtitle: "HdM & Mercedes-Benz",
      companyLogo: "https://eliasmerzhaeuser.com/content/logos/hdm_logo.png",
      partnerLogo: "https://eliasmerzhaeuser.com/content/logos/mercedes-benz_logo.png",
      description: "Ziel dieser Studie ist es, die Formen der Interaktion in einem autonomen Fahrzeug mit digitalen 2D-, wie Karten oder Webseiten und 3D-Inhalten, z.B. 3D-Modellen, zu untersuchen.",
      tags: [
        "👤 👤 👤 👤 👤",
        "Interaction Design",
        "Usability Testing",
        "Front-End Development",
        "Autonomous Driving",
        "VR",
        "AR",
        "3D Design",
        "VARJO XR-3",
        "Unity",
        "Ultraleap",
      ],
      route: "/flexCar",
      link: "",
      images: [
        {
          id: "1",
          alt: "FlexCar Modell",
          url: "https://eliasmerzhaeuser.com/content/flexCar/preview_v2.jpg",
        },
        {
          id: "2",
          alt: "Touchpad",
          url: "https://eliasmerzhaeuser.com/content/flexCar/Concept_EQC_Touchpad.jpg",
        },
        {
          id: "3",
          alt: "SpaceMouse",
          url: "https://eliasmerzhaeuser.com/content/flexCar/SpaceMouse.jpg",
        },
        {
          id: "4",
          alt: "Ghost hands",
          url: "https://eliasmerzhaeuser.com/content/flexCar/Ghost_Hands.jpg",
        },
        {
          id: "5",
          alt: "Konstruktionsplan 1",
          url: "https://eliasmerzhaeuser.com/content/flexCar/MK_Konstruktion_01.png",
        },
        {
          id: "6",
          alt: "Konstruktionsplan 2",
          url: "https://eliasmerzhaeuser.com/content/flexCar/MK_Konstruktion_03.png",
        },
        {
          id: "7",
          alt: "Wireframe 1",
          url: "https://eliasmerzhaeuser.com/content/flexCar/MK_Wired_01.png",
        },
        {
          id: "8",
          alt: "Wireframe 2",
          url: "https://eliasmerzhaeuser.com/content/flexCar/MK_Wired_02.png",
        },
        {
          id: "9",
          alt: "Simulator 2",
          url: "https://eliasmerzhaeuser.com/content/flexCar/Simulator_2.jpg",
        },
        {
          id: "10",
          alt: "Simulator 1",
          url: "https://eliasmerzhaeuser.com/content/flexCar/Simulator_1.jpg",
        },
        {
          id: "11",
          alt: "Video Preview",
          url: "https://eliasmerzhaeuser.com/content/flexCar/GS_House_Scene.jpg",
        },
      ],
      videoURL: ["https://youtu.be/RJ-FUcEsEdI?si=49hc6592oABPbCzY"],
      status: "Beendet",
      text: [
        {
          heading: "Projektziel",
          body: "Trotz umfangreicher und sehr differenzierter Forschung zu Interaktionsmodalitäten beim traditionellen Fahren, beim autonomen Fahren im Allgemeinen oder bei Augmented Reality, gibt es nur wenige Erkenntnisse darüber, wie Interaktionsformen in autonomen Fahrzeugen gestaltet sein müssen, damit ihre Nutzer effizient mit AR-Brillen in einer 2D- oder 3D-Umgebung arbeiten können. Ziel dieser Studie ist es, die Formen der Interaktion in einem autonomen Fahrzeug mit digitalen 2D-, wie Karten oder Webseiten und 3D-Inhalten, z.B. 3D-Modellen, zu untersuchen.",
        },
        {
          heading: "Interaktionskonzept",
          body: "Auf Basis unserer Recherchen haben wir drei einzigartige Interaktionsmethoden ausgewählt, die für die Beantwortung unserer Forschungsfrage relevant sind, nämlich ein Touchpad oder einen Touchscreen, eine 3D-Raummaus und Gestensteuerung.",
        },
        {
          heading: "Touchpad",
          body: "Das Touchpad wurde ausgewählt, weil es weithin bekannt und vertraut ist. Folglich wird erwartet, dass die Integration eines solch vertrauten Geräts in autonome Fahrzeuge auf eine hohe Akzeptanz bei den Nutzern stößt, da es ein Gefühl von Komfort und eine minimale Lernkurve bietet.",
        },
        {
          heading: "3D Maus",
          body: "Angesichts der Einbindung von 3D-Hologrammen in das Anzeigekonzept, die einen neuartigen Interaktionsansatz erfordert, wurde als zweite Interaktionsmodalität eine Space-Mouse mit joystickähnlichen Bedienelementen gewählt. Mit ihren sechs mechanischen Freiheitsgraden (vorwärts/rückwärts, aufwärts/abwärts, links/rechts, gieren, neigen, rollen) ist die Space-Mouse vielversprechend, um Inhalte geschickt im dreidimensionalen Raum zu manövrieren.",
        },
        {
          heading: "Gestensteuerung",
          body: "Als dritte Interaktionsmethode für die Bewertung haben wir uns für eine Gestensteuerung entschieden. Diese Methode, bei der Handgesten im Mittelpunkt stehen, macht physische Berührungen oder Eingabegeräte überflüssig und ermöglicht eine freihändige Interaktion. Handgesten sind eine angeborene Form der Kommunikation und haben den Vorteil, dass sie für alle Benutzer mit unterschiedlichem Hintergrund und Alter intuitiv sind. Abgesehen von ihrer intuitiven Natur bieten Handgesten eine Vielzahl von Interaktionsmöglichkeiten, die von unterschiedlichen Gesten und deren Kombinationen abhängen. Diese Vielseitigkeit steigert das Potenzial für umfassende Kontrolle und Engagement.",
        },
        {
          heading: "Simulator",
          body: "Um zu untersuchen, wie gut diese drei Interaktionsmethoden in einem autonomen Fahrzeugkonzept funktionieren, wurde ein System auf der Grundlage des FlexCar-Projekts entwickelt. Dazu gehörte der FlexCar Mixed Reality Simulator mit der Fahrzeugplattform und den Autositzen sowie das virtuelle Shuttle-Asset. Zusätzlich wurden mit dem Varjo XR3-Headset die Umgebung und der Inhalt simuliert. Da sich unser Anwendungsfall ausschließlich auf die Interaktion mit den im Fahrzeug selbst angezeigten Inhalten konzentrierte, entschieden wir uns für ein statisches Fahrzeug, bei dem die Benutzer in einem der auf der Fahrzeugplattform montierten Autositze Platz nehmen und die XR-Brille tragen würden.",
        },
        {
          heading: "Mittelkonsole",
          body: "Es wurde eine Mittelkonsole entworfen und gebaut, die Einbuchtungen für die Geräte enthält, die für die Interaktion mit dem System benötigt werden, insbesondere für das Touchpad und die Space-Maus. Die Implementierung der Gestensteuerung führte nicht zu weiteren Designvorgaben, da keine Hardware auf der Konsole benötigt wird.",
        },
        {
          heading: "Studienablauf",
          body: "Um Erkenntnisse darüber zu gewinnen, wie neuartige und benutzerfreundliche Interaktionen für die Fahrgäste autonomer Fahrzeuge geschaffen werden können, wurde unsere Studie in zwei verschiedene Testphasen unterteilt. Diese Aufteilung zielte darauf ab, effektiv auf die Bedürfnisse der Fahrgäste einzugehen und gleichzeitig einen bahnbrechenden Ansatz beizubehalten. In der ersten Phase wurde in einer Reihe von umfangreichen Pretests jede gewählte Interaktionsmethode für sich genommen untersucht. Zu den übergeordneten Zielen gehörte es, die individuellen Stärken und Schwächen dieser Methoden zu ermitteln und die Akzeptanz der Teilnehmer zu messen.\n\nAufbauend auf den Erkenntnissen aus dem Nutzerfeedback und den Testauswertungen haben wir in einer weiteren Phase die Vorteile und Stärken der verschiedenen Interaktionskonzepte zu einem einheitlichen System zusammengeführt.\n\nDieses System wurde anschließend einer zweiten Phase von Benutzertests unterzogen, mit dem Ziel, die spezifischen Szenarien zu erkennen, in denen die verschiedenen Facetten des integrierten Systems eingeführt wurden, und seine Durchführbarkeit und Benutzerakzeptanz zu bestätigen.\n\nIn beiden Testphasen haben wir ein gemischtes Methodendesign angewandt, das quantitative (z. B. UEQ, NASA-TLX) und qualitative Datenerhebung (Umfrage, Interview) kombiniert. Dieser Ansatz ermöglichte es uns nicht nur, eine Reihe von Erkenntnissen aus der Sicht der Teilnehmer zu sammeln, sondern auch Trends, Muster und Beziehungen innerhalb der Daten zu quantifizieren.",
        },
        {
          heading: "Ergebnisse",
          body: "Ein kombiniertes System aus Handgestensteuerung und einem Joystick-ähnlichen Gerät wie der 3D-Space-Mouse ist realisierbar und von den Nutzern im Bereich der autonomen Fahrzeuge akzeptiert. Die SpaceMouse ist besonders vorteilhaft, wenn der Benutzer Aufgaben ausführen muss, die Präzision erfordern, wie das langsame Zoomen in einen bestimmten Bereich oder das Klicken auf eine kleine Taste, während Gesten Vorteile bieten, wenn es um Aufgaben geht, bei denen Präzision keine Priorität hat und Geschwindigkeit wichtiger ist - zum Beispiel beim Scrollen großer Listen oder Websites.\n\nEin System, bei dem der Benutzer eine Interaktionsmodalität wählen kann, ist ratsam, da die Benutzer eine Vielzahl individueller Präferenzen gezeigt haben. Darüber hinaus sollte eine haptische Option immer eine Möglichkeit sein, auf die der Nutzer zurückgreifen kann, insbesondere unter dem Aspekt der Barrierefreiheit.",
        },
      ]
    },
    {
      title: "Disposal-App",
      subtitle: "HdM & Porsche",
      companyLogo: "https://eliasmerzhaeuser.com/content/logos/hdm_logo.png",
      partnerLogo: "https://eliasmerzhaeuser.com/content/logos/porsche_logo.png",
      description: "Zusammen mit der Porsche AG haben wir nach Lösungen gesucht, um die Produktion im Automobilbereich nachhaltiger zu gestalten. Dazu haben wir einen App-Prototypen entwickelt, der Daten für eine KI-basierte Bilderkennung sammelt, sodass jeder Porsche Mitarbeiter Entsorgungsmaterialien innerhalb weniger Sekunden scannen kann und ihm der korrekte Entsorgungsort gezeigt wird.",
      tags: [
        "👤 👤 👤 👤 👤 👤",
        "UI Design",
        "Prototyping",
        "Usability Engineering",
        "Usability Testing",
        "Machine Learning",
      ],
      route: "/disposalApp",
      link: "https://github.com/braydentW/braydentw",
      images: [
        {
          id: "1",
          alt: "Preview",
          url: "https://eliasmerzhaeuser.com/content/disposalApp/preview_v2.jpg",
        },
        {
          id: "2",
          alt: "Personas",
          url: "https://eliasmerzhaeuser.com/content/disposalApp/personas.png",
        },
        {
          id: "3",
          alt: "Problem Definition",
          url: "https://eliasmerzhaeuser.com/content/disposalApp/problem_definition.png",
        },
        {
          id: "4",
          alt: "Prototyp",
          url: "https://eliasmerzhaeuser.com/content/disposalApp/detection.png",
        },
        {
          id: "5",
          alt: "Struktur",
          url: "https://eliasmerzhaeuser.com/content/disposalApp/structure.png",
        },
        {
          id: "6",
          alt: "Gamification",
          url: "https://eliasmerzhaeuser.com/content/disposalApp/challenges.png",
        },
        {
          id: "7",
          alt: "KI Visualisierung",
          url: "https://eliasmerzhaeuser.com/content/disposalApp/ai_visualizer.png",
        },
        {
          id: "8",
          alt: "SUS",
          url: "https://eliasmerzhaeuser.com/content/disposalApp/sus.jpg",
        },
        {
          id: "9",
          alt: "Video preview",
          url: "https://eliasmerzhaeuser.com/content/disposalApp/video_preview.jpg",
        }
      ],
      videoURL: ["https://youtu.be/6D1BOHKfxoI"],
      status: "Beendet",
      text: [
        {
          heading: "Aufgabenstellung",
          body: "Zusammen mit der Porsche AG haben wir nach Lösungen gesucht, um die Produktion im Automobilbereich nachhaltiger zu gestalten. Dazu haben wir einen App-Prototypen entwickelt, der Daten für eine KI-basierte Bilderkennung sammelt, sodass jeder Porsche Mitarbeiter Entsorgungsmaterialien innerhalb weniger Sekunden scannen kann und ihm der korrekte Entsorgungsort gezeigt wird.",
        },
        {
          heading: "Problem Definition",
          body: "Um uns der Problemstellung zu nähern haben wir verschiedene Methoden genutzt. Zunächst haben wir Personas definiert, mit ihren \"Pain Points\". \n\nDer erfahrene Porsche Produktionsmitarbeiter versucht, mit der Porsche Disposal-App den Abfall richtig zu entsorgen. Die Funktion zur Bilderkennung hilft dabei zu erkennen, welche Abfallarten aufgrund ihrer physikalischen Beschaffenheit in welche Tonne gehören. Der Arbeiter wird dazu beitragen, den Algorithmus weiterzuentwickeln, indem er Bilder markiert, um die Genauigkeit der Bilderkennung zu erhöhen. \n\nDer Porsche-Produktionspraktikant möchte auch Abfälle mit Hilfe der Porsche Disposal-App korrekt entsorgen. Außerdem möchte der Praktikant Informationen zum Handbuch abrufen und auf die eLearning-Kurse zurückgreifen können, um sich mit dem Produktionsprozess in der Anwendung vertraut zu machen. Bei Problemen mit der Anwendung könnten auch Meldungen über die App eingereicht werden.",
        },
        {
          heading: "Prototyp",
          body: "Bereits in der Anfangsphase der Implementierung unserer Lösung mussten wir die wichtigsten Funktionen der App konzipieren, darunter Kamera/Scanning, Bilderkennung, E-Learning und das Sammeln von Punkten für Belohnungen.",
        },
        {
          heading: "Gamification",
          body: "Nach erfolgreicher Einreichung von Material kann der Mitarbeiter mit der App auch Punkte sammeln. Wenn ein Mitarbeiter genug Punkte sammelt, kann er seine Punkte für bestimmte Belohnungen kaufen/einlösen. Zum Beispiel eine kostenlose Tasse Kaffee oder Porsche-Merchandise, wie T-Shirts. Punkte gibt es auch für das Erfüllen von \"Challenges\". Bei der Aufgabe \"Waste sorting\" muss der Nutzer beispielsweise die richtigen Bilder von Materialien auswählen, die in einen bestimmten Behälter gehören. Dadurch erhält er Punkte und verbessert die Erkennungsgenauigkeit des Algorithmus.",
        },
        {
          heading: "Machine Learning & Object recognition",
          body: "Wir haben uns zunächst auf den Algorithmus zur Materialerkennung konzentriert, dessen einzige Aufgabe es ist, anhand von Trainingsbildern von Porsche vorherzusagen, welches Material vorhanden ist. Zur weiteren Optimierung des Algorithmus können später zusätzliche Algorithmen hinzugefügt werden, die dann nur noch Vorhersagen über z.B. die Lichtverhältnisse, die Verschmutzung oder die Materialvariationen liefern. Aus Expertenbefragungen haben wir gelernt, dass bereits 1000 Bilder pro Abfallstrom ausreichen, um einen Transfer-Learning-Prozess zu starten. Damit sollte es möglich sein, einen Algorithmus initial relativ schnell zu trainieren. Nach dem Trainingsprozess kann die App mit einer Genauigkeit von 70-80% vorhersagen, welches Material vorhanden ist und wo es entsorgt werden muss.",
        },
        {
          heading: "Visualisierung der KI",
          body: "Transparenz ist eine der sieben EU-Richtlinien für den ethischen Einsatz von KI. Der Nutzer soll darüber informiert werden, dass er mit einer KI interagiert. Auch die Visualisierung der aktuellen Erkennungsgenauigkeit wird aufgezeigt und bietet eine informierte Entscheidungsgrundlage für die App-Nutzung.",
        },
        {
          heading: "User Testing / Evaluation",
          body: "Der durchschnittliche ATI-S-Wert aller Probanden lag bei 4,15 von 6 möglichen Punkten. Aus den Ergebnissen lässt sich ablesen, dass ein grundsätzliches Interesse an technischen Systemen besteht und die Probanden über ein Grundwissen verfügen. Diese Erkenntnisse spiegeln sich auch in der Interaktion mit dem High-Fidelity-Prototyp wider. So konnten alle Probanden den Prototyp ohne größere Probleme bedienen und sich darin zurechtfinden.\n\nQuantitative Ergebnisse zur Usability der App wurden mit Hilfe des unten aufgeführten SUS-Scores erhoben. Dazu füllten die Testpersonen nach den Nutzertests einen Fragebogen aus, der anschließend ausgewertet wurde.\n\nDer letzte Teil des Benutzertests war ein standardisiertes, offenes Interview. Die Probanden gaben überwiegend positives Feedback und sagten uns, was sich mit den Ergebnissen des SUS-Fragebogens deckt. Um das Konzept unserer App erneut zu validieren, lautete unsere letzte Frage: Wie häufig würden Sie die App zum Scannen von Abfällen verwenden? Die Antworten lauteten, dass sie die App etwa zwei Mal pro Woche nutzen würden und dass sie sie immer dann verwenden würden, wenn sie sich nicht sicher sind, wo sie etwas entsorgen sollen, was genau unseren Vorstellungen entspricht.",
        }
      ]
    },
    {
      title: "APA Mobile-App",
      subtitle: "Fraunhofer IPA",
      companyLogo: "https://eliasmerzhaeuser.com/content/logos/fraunhofer_logo_white.png",
      description: "Ziel des Projekts ist es, KMUs in die Lage zu versetzen, das Potenzial zu analysieren, das die Automatisierung für ihren Produktionsprozess hat. Das Fraunhofer IPA hat hierfür die Automatisierungs-Potenzialanalyse (APA) entwickelt.",
      tags: [
        "UI Concept",
        "UX Design",
        "Prototyping",
        "Android-Development",
        "Java",
        "Usability Testing"
      ],
      route: "/apaApp",
      link: "",
      images: [
        {
          id: "1",
          alt: "apa_1",
          url: "https://eliasmerzhaeuser.com/content/APA/1.jpg",
        },
        {
          id: "2",
          alt: "apa_2",
          url: "https://eliasmerzhaeuser.com/content/APA/2.jpg",
        },
        {
          id: "3",
          alt: "apa_3",
          url: "https://eliasmerzhaeuser.com/content/APA/3.jpg",
        },
        {
          id: "4",
          alt: "Fitness für Automatisierung @Fraunhofer IPA",
          url: "https://eliasmerzhaeuser.com/content/APA/graph_1.jpg",
        },
        {
          id: "5",
          alt: "Prozess Automatisierungspotenzial @Fraunhofer IPA",
          url: "https://eliasmerzhaeuser.com/content/APA/graph_2.jpg",
        },
        {
          id: "6",
          alt: "App-Struktur",
          url: "https://eliasmerzhaeuser.com/content/APA/screens_1.jpg",
        },
        {
          id: "7",
          alt: "Prozesseigenschaften und Erklärungen",
          url: "https://eliasmerzhaeuser.com/content/APA/screens_2.jpg",
        }
      ],
      videoURL: ["https://www.youtube.com/watch?v=ZZKwxV9gWs4"],
      status: "Live",
      text: [
        {
          heading: "Projektziel",
          body: "Ziel des Projekts ist es, KMUs in die Lage zu versetzen, das Potenzial zu analysieren, das die Automatisierung für ihren Produktionsprozess hat. Das Fraunhofer IPA hat hierfür die Automatisierungs-Potenzialanalyse (APA) entwickelt.",
        },
        {
          heading: "Automatisierungs-Potenzialanalyse (APA)",
          body: "Mit APA lassen sich Automatisierungspotenziale systematisch ermitteln. Dazu wurde eine Mobile-App entwickelt, mithilfe derer eigenständig verborgene Kapazitäten durch Automatisierung identifizieren lassen. Diese lassen sich dann durch Voll- oder teilautomatisierung oder mithilfe von Mensch-Roboter-Kollaboration ausschöpfen.",
        },
        {
          heading: "Meine Rolle",
          body: "Meine Aufgabe war es, eine bessere Benutzererfahrung zu testen und zu schaffen, indem ich die Benutzeroberfläche und den Benutzerfluss verbesserte. Ich gestaltete das Layout in Form eines modularen Systems um, in dem die meisten Elemente der Benutzeroberfläche gruppiert sind und implementierte diese in Android. Außerdem fügte ich eine neue Diagrammbibliothek hinzu, die es ermöglichte, verschiedene Arten von Daten aus der Bewertung anzuzeigen. Zur Erklärung von Fachbegriffen wurden Dialoge mit Gif-Bildern als Beschreibungen hinzugefügt. Neue Funktionen wie das Erfassen der Zeit eines Prozesses oder das Speichern von prozessbezogenen Medien wurden von mir hinzugefügt. Die App wird derzeit vom Fraunhofer IPA im Rahmen seiner Beratungsleistungen eingesetzt. Darüber hinaus erstellte ich auf Basis der Nutzertests ein neues UI Konzept, welches übersichtlicher sein sollte und stärker an bekannte UI Patterns anknüpft.",
        },
        {
          heading: "App-Struktur",
          body: "Zunächst lassen sich in der App Projekte anlegen, welche für Produktionseinheiten stehen. Diesen können verschiedene Montagelinien hinzugefügt werden, die wiederum Prozesse, wie z.B. Schrauben oder Schweißen einschließen. Zu den Montagelinien und Prozessen lassen sich zeitliche, ökonomische Effizienz und das Automatisierungspotenzial einsehen.",
        },
        {
          heading: "Prozesseigenschaften und Erklärungen",
          body: "Zu Prozessen können verschiedene Daten, wie Zeit, Notizen oder Medien hinzugefügt werden, um mögliche Probleme oder Potenziale transparent zu machen. Außerdem können zu bestimmten Eigenschaften Erklärungen in Form von Animationen eingesehen werden.",
        }
      ]
    },
    {
      title: "plus10",
      subtitle: "Fraunhofer IPA & plus10",
      companyLogo: "https://eliasmerzhaeuser.com/content/logos/fraunhofer_logo_white.png",
      partnerLogo: "https://eliasmerzhaeuser.com/content/logos/plus10_logo.png",
      description: "Um langen Ausfallzeiten bei Störungen und Kurzstopps von Maschinen entgegenzuwirken, liefert Shannon®, die operative Werker-Assistenz, den Bedienern einer automatisierten Produktionsanlage situative Problem-Lösungsvorschläge.",
      tags: [
        "Usability Engineering",
        "Front-End Development",
        "React",
        "JavaScript"
      ],
      route: "/plus10",
      link: "",
      images: [
        {
          id: "1",
          alt: "Shannon Smart Watch @plus10",
          url: "https://eliasmerzhaeuser.com/content/plus10/shannon.jpg",
        },
        {
          id: "2",
          alt: "Shannon auf verschiedenen Devices @plus10",
          url: "https://eliasmerzhaeuser.com/content/plus10/screens_1.jpg",
        },
        {
          id: "3",
          alt: "Ereignisse verwalten @plus10",
          url: "https://eliasmerzhaeuser.com/content/plus10/screens_2.jpg",
        },
        {
          id: "4",
          alt: "Lösungswege verwalten @plus10",
          url: "https://eliasmerzhaeuser.com/content/plus10/screens_3.jpg",
        },
      ],
      videoURL: ["https://youtu.be/kx16qKIhra4"],
      status: "Live",
      text: [
        {
          heading: "Projektziel",
          body: "Ziel des Projekts ist es, KMUs in die Lage zu versetzen, das Potenzial zu analysieren, das die Automatisierung für ihren Produktionsprozess hat. Das Fraunhofer IPA hat hierfür die Automatisierungs-Potenzialanalyse (APA) entwickelt.",
        },
        {
          heading: "Meine Rolle",
          body: "Bei plus10 habe ich UI-Komponenten mit JavaScript (React) erstellt und dabei hauptsächlich die vom Designstudio F209 erstellten Mockups nachgebildet. Dabei war es mehrfach meine Aufgabe, eigenständig interaktives Verhalten der Software zu erstellen, das mit dem bestehenden Designkonzept übereinstimmt. Die Komponenten wurden dann in die Shannon-Webanwendung integriert. Darüber hinaus habe ich Usability von von Widget-Interaktionen optimiert.",
        },
        {
          heading: "Shannon",
          body: "Die Anwendung benachrichtigt über Ereignisse und ist in der Lage Fehlerursachen präzise zu lokalisieren. Schritt-für-Schritt-Anleitungen können eingesehen, dokumentiert, geteilt und kommentiert werden, um Behebung des jeweiligen Fehlers/der Störung zu erleichtern. Über das Dashboard der web-basierten Applikation, lassen sich Ereignisse und Lösungswege verwalten.",
        },
      ]
    },
    {
      title: "Xplore",
      subtitle: "HdM & Mercedes-Benz",
      companyLogo: "https://eliasmerzhaeuser.com/content/logos/hdm_logo.png",
      partnerLogo: "https://eliasmerzhaeuser.com/content/logos/mercedes-benz_logo.png",
      description: "Im Forschungsprojekt „Xplore“ wollten wir in Kooperation mit der Hochschule der Medien und „PIONEERING by Daimler“ herausfinden, welche Perspektiven sich für intuitive Interaktionsformen in autonomen Fahrzeugen bieten.",
      tags: [
        "👤 👤 👤 👤 👤 👤",
        "Interaction Design",
        "Usability Engineering",
        "User Testing",
        "Hardware-Prototyping",
        "Raspberry PI",
        "Autonomous Driving",
        "Social Cues"
      ],
      route: "/xplore",
      link: "",
      images: [
        {
          id: "1",
          alt: "MediaNight_Teaser-1",
          url: "https://eliasmerzhaeuser.com/content/Xplore/MediaNight_Teaser-1_2.jpg", // require("../images/plus10/screens_1.png")
        },
        {
          id: "2",
          alt: "MediaNight_Teaser-2",
          url: "https://eliasmerzhaeuser.com/content/Xplore/MediaNight_Teaser-2.jpg", // require("../images/plus10/screens_2.png")
        },
        {
          id: "3",
          alt: "MediaNight_Teaser-3",
          url: "https://eliasmerzhaeuser.com/content/Xplore/MediaNight_Teaser-3.jpg", // require("../images/plus10/screens_3.png")
        },
        {
          id: "4",
          alt: "MediaNight_Teaser-4",
          url: "https://eliasmerzhaeuser.com/content/Xplore/MediaNight_Teaser-4.jpg", // require("../images/plus10/screens_3.png")
        },
        {
          id: "5",
          alt: "MediaNight_Teaser-5",
          url: "https://eliasmerzhaeuser.com/content/Xplore/MediaNight_Teaser-5.jpg", // require("../images/plus10/screens_3.png")
        },
        {
          id: "6",
          alt: "MediaNight_Teaser-6",
          url: "https://eliasmerzhaeuser.com/content/Xplore/MediaNight_Teaser-6.jpg", // require("../images/plus10/screens_3.png")
        },
        {
          id: "7",
          alt: "Skizze Fahrersitz",
          url: "https://eliasmerzhaeuser.com/content/Xplore/sketch_1.jpg", // require("../images/plus10/screens_3.png")
        },
        {
          id: "8",
          alt: "Skizze Innenraum",
          url: "https://eliasmerzhaeuser.com/content/Xplore/sketch_2.jpg", // require("../images/plus10/screens_3.png")
        },
        {
          id: "9",
          alt: "Interaktion über Social Cues",
          url: "https://eliasmerzhaeuser.com/content/Xplore/use_case_2.jpg", // require("../images/plus10/screens_3.png")
        },
        {
          id: "10",
          alt: "Use Case 1 - Ruhezustand",
          url: "https://eliasmerzhaeuser.com/content/Xplore/use_case_1/2.JPG", // require("../images/plus10/screens_3.png")
        },
        {
          id: "11",
          alt: "Use Case 1 - Begrüßung",
          url: "https://eliasmerzhaeuser.com/content/Xplore/use_case_1/3.JPG", // require("../images/plus10/screens_3.png")
        },
        {
          id: "12",
          alt: "Use Case 1 - Bestätigung erbeten",
          url: "https://eliasmerzhaeuser.com/content/Xplore/use_case_1/4.JPG",
        },
        {
          id: "13",
          alt: "Use Case 1 - Bestätigung erteilt",
          url: "https://eliasmerzhaeuser.com/content/Xplore/use_case_1/5.JPG",
        },
        {
          id: "14",
          alt: "Use Case 1 - Bestätigung verstanden",
          url: "https://eliasmerzhaeuser.com/content/Xplore/use_case_1/6.JPG",
        },
        {
          id: "15",
          alt: "Use Case 2 - Blick auf POI",
          url: "https://eliasmerzhaeuser.com/content/Xplore/use_case_2/1.JPG",
        },
        {
          id: "16",
          alt: "Use Case 2 - Blickrichtung wahrgenommen",
          url: "https://eliasmerzhaeuser.com/content/Xplore/use_case_2/2.JPG",
        },
        {
          id: "17",
          alt: "Use Case 2 - Interaktionsangebot bereitgestellt",
          url: "https://eliasmerzhaeuser.com/content/Xplore/use_case_2/3.JPG",
        },
        {
          id: "18",
          alt: "Use Case 2 - Interaktion ausgeführt",
          url: "https://eliasmerzhaeuser.com/content/Xplore/use_case_2/4.JPG",
        },
        {
          id: "19",
          alt: "Use Case 2 - Feedback zur Interaktion",
          url: "https://eliasmerzhaeuser.com/content/Xplore/use_case_2/5.JPG",
        },
        {
          id: "20",
          alt: "Ablauf User Test",
          url: "https://eliasmerzhaeuser.com/content/Xplore/User_Test.png",
        },
        {
          id: "21",
          alt: "Bill Smith",
          url: "https://eliasmerzhaeuser.com/content/Xplore/Bill_Smith.png",
        },
        {
          id: "22",
          alt: "Hatice Demiray",
          url: "https://eliasmerzhaeuser.com/content/Xplore/Hatice_Demiray.png",
        },
        {
          id: "23",
          alt: "Hans Weber",
          url: "https://eliasmerzhaeuser.com/content/Xplore/Hans_Weber.png",
        },
      ],
      videoURL: ["https://youtu.be/yXMrZ8ADPVk"],
      status: "Beendet",
      text: [
        {
          heading: "Forschungsziel",
          body: "Im Forschungsprojekt „Xplore“ wollten wir in Kooperation mit der Hochschule der Medien und „PIONEERING by Daimler“ herausfinden, welche Perspektiven sich für intuitive Interaktionsformen in autonomen Fahrzeugen bieten.",
        },
        {
          heading: "Interaktion über Social Cues",
          body: "Über ein implizites Interaktionskonzept wollen wir neue Wege aufzeigen, wie man mit einer für Menschen natürlichen Art und Weise mit dem Fahrzeug agieren kann. Der Aufbau einer organischen und intuitiven Art der Interaktion, die mit Social Cues arbeitet, soll dabei das Fahrzeug zu einem digitalen Begleiter werden lassen. Das Konzept soll zudem Passagieren ermöglichen, die Wahrnehmungsfähigkeit aus dem Auto heraus zu erweitern, um direkter mit der Umwelt interagieren zu können.",
        },
        {
          heading: "Lichtimpulse & Feedback-Layer",
          body: "Der Fokus liegt dabei auf einer dezentralisierten Steuerung, die es dem Passagier erlaubt, mit dem gesamten Fahrzeuginnenraum in Interaktion zu treten. Durch Lichtimpulse und Leuchtstreifen wird dieser durch das Fahrzeug und zu den ortsunabhängigen, aufleuchtenden System-Bedienelementen geleitet. Dabei signalisiert das Fahrzeug über nicht-invasive Feedback-Layer mittels Licht, Sound und Vibration, dass die Interaktionen der Insassen wahrgenommen, verstanden und anschließend entsprechende Anweisungen ausgeführt werden. Ziel unseres Forschungsprojektes ist es, mit der Veränderung der Nutzung eines Fahrzeuges, ein neues Raumkonzept zu entwickeln, das sich flexibel an die Wünsche und Bedürfnisse des Passagiers anpassen kann.",
        },
        {
          heading: "Szenario 1",
          body: "Das Interaktionskonzept stellt ein Gesamterlebnis bestehend aus drei, unterschiedlichen Fahr-Szenarien dar. Dabei stellt das erste Szenario die wechselseitige Aktivierung zwischen Fahrzeug und Passagier dar. Diese beginnt mit dem Platz nehmen des Passagiers und endet mit der Bestätigung der Anfrage zu starten.",
        },
        {
          heading: "Szenario 2",
          body: "Szenario Zwei zeigt die Möglichkeiten eines Explorativ-Modus. Ist dieser während der Fahrt eingeschaltet, ist es dem Passagier möglich, aus dem Auto heraus mit der Umwelt zu interagieren.\n\n Ist der Explorativ-Modus eingeschaltet, kann der Passagier mit der Umgebung in Interaktion treten und sich Informationen und Eindrücke von außen ins Fahrzeug holen. Beim Entdecken eines Point of Interest, agiert das System und bietet eine Auswahl an verschiedenen Interaktionsmöglichkeiten an. Dabei kann der Passagier zwischen den Vorschlägen von Musik, Informationen, sowie Bilder und Videos zum POI auswählen. Da das Fahrzeug die Wünsche und Bedürfnisse des Passagiers kennt, weist es diesen auch durch proaktive Vorschläge auf spannende Orte in dessen Umfeld hin.",
        },
        {
          heading: "User Test",
          body: "Um die User Experience und Usability zu evaluieren wurden Nutzerinterviews mit mehreren Probanden durchgeführt. Aufgrund der Corona-Pandemie wurde das Konzept anhand von POV-Aufnahmen demonstriert. Die Auswertung der Ergebnisse hat ergeben, dass die UX als attraktiv zu bewerten ist. Allerdings haben die Nutzer Vorbehalte bezüglich der Nützlichkeit des UI und der Verlässlichkeit des autonomen Fahrzeugs.",
        },
        {
          heading: "User Research",
          body: "Anhand verschiedener demographischer Kategorien haben wir zunächst drei Personas erstellt. Durch Interviews mit dementsprechend Personas verifizierten die Merkmale der Personas. Wir orientierten uns hier auch an der Zielgruppe einer möglichen Mercedes-Benz Zielgruppe.",
        },
      ]
    },
    {
      title: "drinq",
      subtitle: "HdM",
      companyLogo: "https://eliasmerzhaeuser.com/content/logos/hdm_logo.png", // require("../images/fraunhofer_logo_white.png"),
      description: "Drinq ist ein Tracker für die Wasser- und Koffeinaufnahme und zeigt nützliche Statistiken an und motiviert den Nutzer mit Gamification Funktionen.",
      tags: [
        "👤 👤",
        "UI Design",
        "Prototyping",
        "Material Design",
        "Mobile-App Development",
        "iOS-Development",
        "Swift"
      ],
      route: "/drinq",
      link: "https://github.com/braydentW/braydentw",
      images: [
        {
          id: "1",
          alt: "Wizard",
          url: "https://eliasmerzhaeuser.com/content/drinq/preview_V2.jpg",
        },
        {
          id: "2",
          alt: "Wizard",
          url: "https://eliasmerzhaeuser.com/content/drinq/wizard.jpg",
        },
        {
          id: "3",
          alt: "Screens",
          url: "https://eliasmerzhaeuser.com/content/drinq/screens.jpg",
        },

      ],
      videoURL: [],
      status: "Beendet",
      text: [
        {
          heading: "Health & Gamification",
          body: "Drinq ist ein Tracker für die Wasser- und Koffeinaufnahme und zeigt nützliche Statistiken an und motiviert den Nutzer mit Gamification Funktionen.",
        },
        {
          heading: "Apple Health-Kit",
          body: "Die iOS-App wurde im Kurs Mobile Application Development iOS konzipiert und entwickelt. Die Gesundheitsdaten werden über das Apple HealthKit gelesen und geschrieben und erfüllen höchste Sicherheitsstandards.",
        },
        {
          heading: "User Interface",
          body: "Ein wizard soll zunächst über die Hauptfunktionen aufklären. Darüber hinaus gibt es vier Screens. Der Homescreen bietet eine Übersicht über alle Daten und Challenges geben. In den Challenges können absolvierte und unerfüllte Herausforderungen einsehen. Die Statistiken bieten eine Detailansicht der registrierten Flüssigkeits- und Koffeinaufnahme.",
        },
      ]
    },
    {
      title: "PNDLR",
      subtitle: "HdM",
      companyLogo: "https://eliasmerzhaeuser.com/content/logos/hdm_logo.png", // require("../images/fraunhofer_logo_white.png"),
      description: "Die PNDLR-App ist ein Service für Studierende, die zur Universität Stuttgart oder zur Hochschule der Medien pendeln. Sie basiert auf der Idee, dass die Nutzer häufig auf denselben Strecken unterwegs sind und informiert werden wollen, wann die nächste Verbindung abfährt und dies auf dem Homescreen sehen möchten.",
      tags: [
        "👤 👤",
        "UI Design",
        "Prototyping",
        "Material Design",
        "Mobile-App Development",
        "Android-Development",
        "Java"
      ],
      route: "/pndlr",
      link: "https://github.com/braydentW/braydentw",
      images: [
        {
          id: "1",
          alt: "Preview",
          url: "https://eliasmerzhaeuser.com/content/pndlr/preview_V2.jpg",
        },
        {
          id: "2",
          alt: "Screens",
          url: "https://eliasmerzhaeuser.com/content/pndlr/screens.jpg",
        }
      ],
      videoURL: [],
      status: "Beendet",
      text: [
        {
          heading: "Mobil am Campus",
          body: "Die PNDLR-App ist ein Service für Studierende, die zur Universität Stuttgart oder zur Hochschule der Medien pendeln. Sie basiert auf der Idee, dass die Nutzer häufig auf denselben Strecken unterwegs sind und informiert werden wollen, wann die nächste Verbindung abfährt und dies auf dem Homescreen sehen möchten.",
        },
        {
          heading: "Nächste Verbingung",
          body: "Favorisierte Routen, die aktualisiert werden, sowie anpassbare Benachrichtigungen wurden implementiert. Die Android-App wurde im Rahmen des Kurses Mobile Application Development entworfen und entwickelt. Für die Verbindungsinformationen wurde eine Open-Source API genutzt.",
        }
      ]
    },
    {
      title: "Quizzit",
      subtitle: "HdM",
      companyLogo: "https://eliasmerzhaeuser.com/content/logos/hdm_logo.png",
      description: "Im Rahmen des Veranstaltung \"Smarthome Praktikum\" sollte ein Spiel mit Smarthome Geräten entwickelt werden, welches die Bewohner eines Seniorenheimes sowohl mental, als auch physisch aktiviert.",
      tags: [
        "👤 👤",
        "Smart Home",
        "Internet of Things",
        "Individualisierung",
        "Games",
        "Usability Engineering",
        "Accessability",
      ],
      route: "/quizzit",
      link: "",
      images: [
        {
          id: "1",
          alt: "Preview",
          url: "https://eliasmerzhaeuser.com/content/quizzit/preview_v2.jpg",
        },
        {
          id: "2",
          alt: "Gyrowürfel",
          url: "https://eliasmerzhaeuser.com/content/quizzit/gyro_dice.jpg",
        },
        {
          id: "3",
          alt: "Smart Home Lampen",
          url: "https://eliasmerzhaeuser.com/content/quizzit/lamp.jpg",
        },
        {
          id: "4",
          alt: "Video Preview",
          url: "https://eliasmerzhaeuser.com/content/quizzit/video_preview.jpg",
        }
      ],
      videoURL: ["https://youtu.be/7rDZXzv6vwo"],
      status: "Beendet",
      text: [
        {
          heading: "Projektziel",
          body: "Im Rahmen des Veranstaltung \"Smarthome Praktikum\" sollte ein Spiel mit Smarthome Geräten entwickelt werden, welches die Bewohner eines Seniorenheimes sowohl mental, als auch physisch aktiviert.",
        },
        {
          heading: "Personas",
          body: "Basierend auf einer Zielgruppenanalyse haben wir fünf Personas definiert, von denen wir wiederum vier notwendige Personalisierungsmöglichkeiten ableiten konnten. Die Personas sind als spielbare Profile im Spiel hinterlegt.",
        },
        {
          heading: "Spielkonzept",
          body: "Bei dem Spiel handelt es sich um ein Trivia Quiz. Die Spieler würfeln mit einem Schaumstoff-Gyroskop-Würfel. Für körperlichen Eingeschränkte ist ein digitale Würfel-Funktion vorhanden. Die Auswertung der Antworten wird über vier farbige Lampen kommuniziert, die im Raum aufgestellt sind.",
        },
        {
          heading: "",
          body: "Es werden Quizfragen mit vier Antwortmöglichkeiten gestellt in Themengebieten wie Natur, Technik, Filme, oder auch die eigene Ortsgeschichte. Nur, wenn der Spieler die Frage richtig beantwortet, zieht seine Spielfigur auf dem Spielbrett um die gewürfelte Zahl weiter.",
        },
        {
          heading: "App (Desktop & Mobil)",
          body: "Entwickelt wurde die Anwendung auf der Unity-Plattform. Über die Windowns Desktop App, können die Spieler gemeinsam auf einem großen Bildschirm spielen. So können alle Mitspieler das Spielbrett und den aktuellen Spielstand verfolgen.\n\nDie Mobile App läuft auf einem Tablet mit dem das Spiel, wie mit einer Fernbedienung gesteuert wird. Dieses wird nach jedem Zug von Spieler zu Spieler weitergereicht. Bei jedem Spielerwechsel lädt es automatisch die hinterlegten Präferenzen des nächsten Spielers und verändert so beispielsweise die Darstellungsgröße des Benutzerinterfaces, oder schaltet eine Sprachausgabe ein.",
        }
      ]
    },
    {
      title: "EcoGuide",
      subtitle: "HdM & Mercedes-Benz",
      companyLogo: "https://eliasmerzhaeuser.com/content/logos/hdm_logo.png",
      partnerLogo: "https://eliasmerzhaeuser.com/content/logos/mercedes-benz_logo.png",
      description: "Der EcoGuide ist ein Konzept für ein spielbasiertes Assistenzsystem für Onboard Feedback zur Unterstützung von Eco-Driving. Die Forschungsfrage der Arbeit lautet: „Welche nutzerseitigen Bedürfnisse und Anforderungen gibt es im Zusammenhang mit spielbasiertem Onboard Eco-Driving Feedback und welche Anforderungen für die Gestaltungsparameter leiten sich daraus ab?“",
      tags: [
        "UI Concept",
        "Animation",
        "Low-Fidelity Prototyping",
        "Bachelor-Thesis",
        "Usability Testing",
        "Remote User Testing",
        "User Interview"
      ],
      route: "/ecoGuide",
      link: "",
      images: [
        {
          id: "1",
          alt: "ecoGuide_1",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/ecoGuide_preview_2.jpg"
        },
        {
          id: "2",
          alt: "ecoGuide_2",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/ecoGuide_preview_1.jpg"
        },
        {
          id: "3",
          alt: "Games with a purpose",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/games_with_a_purpose.jpg"
        },
        {
          id: "4",
          alt: "EcoPoints, Bestenliste, Herausforderungen & Belohnungen",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/spielelemente_1.jpg"
        },
        {
          id: "5",
          alt: "One-Pedal-Drive Tutorial & Letze Fahrt",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/spielelemente_2.jpg"
        },
        {
          id: "6",
          alt: "Bedienkonzept",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/bedienkonzept.jpg"
        },
        {
          id: "7",
          alt: "Onboard-Feedback",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/onboard_feedback.jpg"
        },
        {
          id: "8",
          alt: "User Flow Szenario 1",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/user_flow_1.jpg"
        },
        {
          id: "9",
          alt: "User Flow Szenario 2",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/user_flow_2.jpg"
        },
        {
          id: "10",
          alt: "User Flow Szenario 3",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/user_flow_3.jpg"
        },
        {
          id: "11",
          alt: "Studienaufbau",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/studienaufbau.jpg"
        },
        {
          id: "12",
          alt: "Studienergebnisse",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/studienergebnisse.jpg"
        },
        {
          id: "13",
          alt: "Nutzerakzeptanz Hilfestellungen",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/mindmap_hilfestellungen.jpg"
        },
        {
          id: "14",
          alt: "Nutzerakzeptanz Gamification",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/mindmap_gamification.jpg"
        },
        {
          id: "15",
          alt: "EcoGuide Home Preview",
          url: "https://eliasmerzhaeuser.com/content/ecoGuide/ecoGuide_home.jpg"
        }
      ],
      videoURL: [
        "https://youtu.be/ovgJVShc7g0",
        "https://youtu.be/B7CX-fvd0nM",
        "https://youtu.be/znjxlLgv5Ok",
      ],
      status: "Beendet",
      text: [
        {
          heading: "Der EcoGuide",
          body: "Der EcoGuide ist ein Konzept für ein spielbasiertes Assistenzsystem für Onboard Feedback zur Unterstützung von Eco-Driving. Ausgearbeitet habe ich es im Rahmen meiner Bachelor-Thesis. Die Forschungsfrage der Arbeit lautet: „Welche nutzerseitigen Bedürfnisse und Anforderungen gibt es im Zusammenhang mit spielbasiertem Onboard Eco-Driving Feedback und welche Anforderungen für die Gestaltungsparameter leiten sich daraus ab?“",
        },
        {
          heading: "Forschungsansatz",
          body: "Als Untersuchungsmethode wurden Nutzerinterviews und eine Umfrage durchgeführt. Der dafür entworfene Low-Fidelity Prototyp besteht aus drei verschiedene Szenarien, während der Fahrt und im Stand. Umgesetzt wurde dieser in Form von Videos, welche den Probanden auch einen Eindruck über den zeitlichen Aspekt der Animationen geben. Der Entwurf fokussiert die Verbindung des Feedbacks und der Gamification.",
        },
        {
          heading: "Games with a purpose",
          body: "Um die Fahrer langfristig zum Einsparen von Energie zu motivieren wurde ein Gamification-Ansatz eingesetzt. Games with a Purpose sind vollständig integrierte Spiele in spielfremden Kontext, Hybrid-Modell zwischen Gamification und Serious Games.",
        },
        {
          heading: "Spielelemente",
          body: "Als Gamification Elemente wurden Punkte, Herausforderungen und Belohnungen eingesetzt, um die Fahrer zu motivieren. Dieses Auswahl gründet auf einer Recherche im Kontext des elektrischen Fahrens, Nachhaltigkeit und Gamification gewählt.",
        },
        {
          heading: "Bedienkonzept",
          body: "Es existieren drei verschiedene Touchpoints mit dem Assistenzsystem. Das Feedback wird über Head-Up Display (HUD) und das Instrument Cluserte (IC) ausgespielt. Die Darstellung im HUD besteht aus drei Teilen (Links Events/Mitte Geschwindigkeit/ Rechtst Navi). Die Anzeige im IC ist nur in Bezug auf die Anzeige der Punkte relevant. Der dritte Teil ist die Navigation, welche für die Gamification aber keine Rolle spielt.",
        },
        {
          heading: "Onboard-Feedback",
          body: "Das Echtzeit-Feedback soll das Fahrverhalten positiv beeinflussen und gleichzeitig einen Spielspaß erwirken. Dazu gibt es verschiedene Indikatoren (One-Pedal-Drive, Rekuperation, Geschwindigkeitsbereich), welche Rückmeldung zur Effizienz des Beschleunigungs-/Verlangsamungsprozesses geben.",
        },
        {
          heading: "Szenario 1",
          body: "Im ersten Fahrszenario bekommt der Fahrer Hilfe bei der energieeffizienten Verlangsamung des Fahrzeugs. Diese zeigt ihm wann dieser den Fuß vom Fahrpedal nehmen soll, um vor dem nächsten Hindernis zum stehen zu kommen. Die Linie auf der Straße zeigt an, wie viel der Distanz bereits durch korrekte Rekuperation ökologisch verlangsamt wurde.",
        },
        {
          heading: "Szenario 2",
          body: "Während im ersten Fahrzenario der Nutzer auf ein Hindernis auffährt und verlangsamen soll, wird der Fahrer im zweiten Fahrszenario daran erinnert, dass moderate Geschwindigkeiten auch ohne Geschwindigkeitsbegrenzung ökologischer sind. Daher bekommt dieser Hilfestellungen und Vorschläge für eine angepasste Fahrweise. Da Spitzengeschwindigkeiten ein wesentlicher Faktor zur Vermeidung von hohem Energieverbrauch sind, wird dem Fahrer ein ökologischer Geschwindigkeitsbereich vorgeschlagen. Erreicht der Fahrer einen ökologischen Bereich wieder, wird ihm dies über Einfärbungen im HUD/IC signalisiert und auch hier bekommt er Punkte.",
        },
        {
          heading: "Szenario 3",
          body: "Das dritte Nutzungsszenario des Assistenzsystems, ist die Bedienung der Head Unit Anwendung. Der Homescreen des EcoGuides, beinhaltet Spielstatistiken, Informationen zur Energieeffizienz und die Aufgabenauswahl. Fahrer können selbst entscheiden, welche Funktionen des Systems sie nutzen wollen. Das heißt, dass Hilfestellungen oder Gamification Elemente auch deaktiviert werden können.",
        },
        {
          heading: "Studienaufbau & Ergebnisse",
          body: "Zum Einsatz kamen eine Umfrage und Nutzerinterviews zur Erhebung von quantitativen und qualitativen Daten. Für die Abfrage der UX und der Technikaffinität wurden die Standardisierte Fragebögen ATI-S-Skala und UEQ-S verwendet.",
        },
        {
          heading: "Nutzerakzeptanz",
          body: "In der Studie wurde die Nutzerakzeptanz der Hilfestellungen und der Gamification analysiert und im Rahmen einer qualitativen Inhaltsanalyse Kategorisiert und mit Mindmaps visualisiert.",
        },
      ]
    },
    {
      title: "VVS Chatbot",
      subtitle: "HdM & VVS",
      companyLogo: "https://eliasmerzhaeuser.com/content/logos/hdm_logo.png",
      partnerLogo: "https://eliasmerzhaeuser.com/content/logos/vvs_logo.png",
      description: "Das Projekt umfasste eine quantitative und qualitative Analyse der mobilen Applikation VVS Chatbots und VVS Mobil-App. Dabei wurden besonders die Aspekte der Barrierefreiheit, Usability und User Experience betrachtet.",
      tags: [
        "👤 👤",
        "Usability Engineering",
        "Usability Testing",
        "Qualitative Analyse",
        "Quantitative Analyse",
      ],
      route: "/vvsChatbot",
      link: "",
      images: [
        {
          id: "1",
          alt: "Preview",
          url: "https://eliasmerzhaeuser.com/content/vvsChatbot/preview.jpg",
        },
        {
          id: "2",
          alt: "Lisa",
          url: "https://eliasmerzhaeuser.com/content/vvsChatbot/Lisa.png",
        },
        {
          id: "3",
          alt: "Max",
          url: "https://eliasmerzhaeuser.com/content/vvsChatbot/Max.png",
        },
        {
          id: "4",
          alt: "Vorbereitungen",
          url: "https://eliasmerzhaeuser.com/content/vvsChatbot/preparation.jpg",
        },
        {
          id: "5",
          alt: "Durchführung",
          url: "https://eliasmerzhaeuser.com/content/vvsChatbot/execution.jpg",
        },
        {
          id: "6",
          alt: "Analyse",
          url: "https://eliasmerzhaeuser.com/content/vvsChatbot/analysis.jpg",
        },
        {
          id: "7",
          alt: "Ergebnisse",
          url: "https://eliasmerzhaeuser.com/content/vvsChatbot/results.jpg",
        },
        {
          id: "8",
          alt: "Video Preview",
          url: "https://eliasmerzhaeuser.com/content/vvsChatbot/video_preview.jpg",
        }
      ],
      videoURL: ["https://youtu.be/LLqN4jpW-y4"],
      status: "Beendet",
      text: [
        {
          heading: "Usability Analyse",
          body: "Das Projekt umfasste eine quantitative und qualitative Analyse der mobilen Applikation VVS Chatbots und VVS Mobil-App. Dabei wurden besonders die Aspekte der Barrierefreiheit, Usability und User Experience betrachtet.",
        },
        {
          heading: "Zielgruppe",
          body: "Teil der Studie ist, Aspekte der Barrierefreiheit für Benutzer mit Sehbehinderungen zu testen. Dadurch erweitert sich die Zielgruppe dieser Studie, durch Personen mit Sehbehinderungen. Allgemein ist die Zielgruppe eine heterogene Population.",
        },
        {
          heading: "Studienaufbau",
          body: "Verglichen wurde die Bedienung mit der Tastatur und der Diktierfunktion, sowie dem Screenreader. Im Rahmen der Studie wurden verschiedene Metriken anhand eines Remote Benutzertests und einer Umfrage ermittelt. Es wird auch auf die Eigenschaften und Einschränkungen von Remote Tests eingegangen.",
        },
        {
          heading: "Ergebnisse",
          body: "Durch eigene Untersuchungen konnten grundlegende Defizite in der Usability, speziell in Bezug auf die Barrierefreiheit festgestellt werden. So unterstützen beide Applikationen die Bedienung durch die Sprachsteuerung nicht ausreichend. Die quantitative Analyse hat ergeben, dass die VVS Mobil Applikation insgesamt in den messbaren Metriken bessere Werte aufweist. Diese Ergebnisse wurden auch durch qualitative Beobachtungen bestätigt. Eine wesentliche Schwäche des VVS Chatbot sind die wenig informativen Fehlermeldungen und eine mangelnde Dokumentation der Funktionsweise. Anhand einer kombinierten Metrik wurde erfasst, dass gerade die Bedienung von VVS Mobil mit der Tastatur eine bessere Usability aufweist, als die Bedienung des Chatbots mit der Tastatur. Bei der Diktierfunktion ist der Unterschied weniger deutlich, wobei immer noch VVS Mobil als benutzerfreundlicher einzustufen ist.",
        }
      ]
    }
  ]
};
export default data;