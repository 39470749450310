import React from "react";
import SkillCard from "./SkillCard";

function Skills({ skills, title }) {
  return (
    <div className="">
      <h1 className="text-gradient text-3xl md:text-4xl md:leading-normal text-left">{title}</h1>
    <div className="mx-auto grid grid-cols-1 lg:grid-cols-4 sm:grid-cols-2 gap-8">
      {skills.map((skill) => {
        return (
          <SkillCard skillName={skill.skillName} skillIcon={skill.skillIcon} skillTags={skill.tags}/>
        );
      })}
    </div>
    </div>
  );
}

export default Skills;
