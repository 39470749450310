import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import VideoPlayer from "./VideoPlayer";
import TextField from "./TextField";
import ImageSlider from "./ImageSlider";
import ProjectHeader from "./ProjectHeader";
import Image from "./Image";
import data from "../assets/data";
import Tag from "../partials/Tags"

const APA = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    AOS.init({
      // once: true,
    });
  });

  const project = data.projects[4];
  const images = project.images;
  const title = project.title;
  const text = project.text;
  const videoURL = project.videoURL;
  const companyLogo = project.companyLogo;
  const tags = project.tags;

  return (
    <div className="">
      <div data-aos="fade-down" data-aos-duration="500">
        <ProjectHeader title={title} companyLogo={companyLogo} tags={tags} />
      </div>
      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" className="content-wrapper">
      <ImageSlider images={images.slice(0, 3)} />

        {/* Projektziel */}
        <TextField hasMarginTop={true} heading={text[0].heading} description={text[0].body} link={"https://www.ipa.fraunhofer.de/de/Kompetenzen/roboter--und-assistenzsysteme/montage-automatisierung/Analyse-des-Automatisierungspotenzials_in_der_Montage.html" } linkText={"APA Fraunhofer IPA"} />
        <VideoPlayer images={images[0]} videoURL={videoURL} />

        {/* Automatisierungs-Potenzialanalyse (APA) */}
        <TextField hasMarginTop={true} heading={text[1].heading} description={text[1].body} />
        <div className="md:grid md:grid-cols-3 md:gap-8 md:max-w-4xl m-auto">
          <Image colSpan="md:col-span-1" image={images[3]} />
          <Image colSpan="md:col-span-2" image={images[4]} />
        </div>

        {/* Meine Rolle*/}
        <TextField hasMarginTop={true} heading={text[2].heading} description={text[2].body}  />

        {/* App-Struktur */}
        <TextField hasMarginTop={true} heading={text[3].heading} description={text[3].body}  />
        <Image image={images[5]} />

        {/* Prozesseigenschaften und Erklärungen */}
        <TextField hasMarginTop={true} heading={text[4].heading} description={text[4].body}  />
        <Image image={images[6]} />
      </div>
    </div>
  );
}

export default APA;