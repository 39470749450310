import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import VideoPlayer from "./VideoPlayer";
import TextField from "./TextField";
import ImageSlider from "./ImageSlider";
import ProjectHeader from "./ProjectHeader";
import Image from "./Image";
import data from "../assets/data";
import Tag from "./Tags";

const Quizzit = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    AOS.init({
      // once: true,
    });
  });

  const project = data.projects[9];
  const images = project.images;
  const title = project.title;
  const text = project.text;
  const videoURL = project.videoURL;
  const companyLogo = project.companyLogo;
  const tags = project.tags;

  return (
    <div>
      <div data-aos="fade-down" data-aos-duration="500">
        <ProjectHeader title={title} companyLogo={companyLogo} tags={tags} />       
      </div>
      <div data-aos="fade-up" data-aos-duration="800" data-aos-delay="400" className="content-wrapper">
        
        <Image image={images[0]} />

         {/* Projektziel */}
         <TextField hasMarginTop={true} heading={text[0].heading} description={text[0].body} />

        {/* Personas */}
        <TextField hasMarginTop={true} heading={text[1].heading} description={text[1].body} />

        {/* Spielkonzept */}
        <TextField hasMarginTop={true} heading={text[2].heading} description={text[2].body} />
        <ImageSlider images={images.slice(1, 3)} />
        <TextField hasMarginTop={true} heading={text[3].heading} description={text[3].body} />

        {/* App (Desktop & Mobil) */}
        <TextField hasMarginTop={true} heading={text[4].heading} description={text[4].body} />

        {/* Video */}
        <VideoPlayer images={images[3]} videoURL={videoURL[0]} />
      </div>
    </div>
  );
}

export default Quizzit;