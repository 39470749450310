import React from "react";
import classnames from "classnames";

function Image(props) {

  const image = props.image;
  const colSpan = props.colSpan;
  const shadow = props.shadow;

  const wrapperClass = classnames(
    "flex flex-col",
    colSpan
  );

  const imgClass = classnames(
    "w-full mt-8",
    shadow
  );

  return (
    <div className={wrapperClass} >
      <img
        className={imgClass}
        onContextMenu="return false;"
        id="img"
        src={image.url}
        alt={image.alt}
      />
      <p className="mx-auto mt-2 text-base text-center font-light md:text-center text-ui">
        {image.alt}
      </p>
    </div>
  );
}

export default Image;