import React, { useState, useEffect } from "react";
import Button from "./Button";
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import Tags from "./Tags";

function ProjectHead(props) {

  const [isDesktop, setDesktop] = useState(window.innerWidth > 800);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 800);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div>
      <div className="flex mb-8 padding-header">
        {isDesktop ? (
          <div className="flex-1">
            <Link to="/">
              <Button buttonText="Back">
                <FaChevronLeft className="mr-2 icon-ui" />
              </Button>
            </Link>
          </div>
        ) : (
          <div className="flex-1">
            <Link to="/">
              <Button>
                <FaChevronLeft className="icon-ui" />
              </Button>
            </Link>
          </div>
        )}
        <div className="flex justify-self-start">
          <img
            className="justify-self-end object-scale-down h-10 w-10 rounded-sm"
            src={props.companyLogo}
            alt="company logo"
          />
          {props.partnerLogo ? (
            <img
              className="justify-self-end object-scale-down h-10 w-10 rounded-sm ml-4"
              src={props.partnerLogo}
              alt="company logo"
            />
          ) : ("")}
        </div>
      </div>

      {(props.isImprint) ? (
           <div className="text-wrapper">
           <h1 className="mb-8 text-3xl md:text-4xl md:leading-relaxed text-gradient text-left text-shadow">
             {props.title}
           </h1>
           {(props.tags) ? (
             <Tags tags={props.tags} gap={"gap-2"} />
           ) : ("")}
         </div>
        ) : (
          <div className="content-wrapper">
          <h1 className="mb-8 text-3xl md:text-4xl md:leading-relaxed text-gradient text-left text-shadow">
            {props.title}
          </h1>
          {(props.tags) ? (
            <Tags tags={props.tags} gap={"gap-2"} />
          ) : ("")}
        </div>
        )}
    </div>
  );
}

export default ProjectHead;