import React from "react";
import PlayButton from "./PlayButton";
import { FaPlay } from "react-icons/fa";
import ReactPlayer from 'react-player'

function VideoPlayer(props) {

    const image = props.images;
    const videoURL = props.videoURL;
    
    return (
        <div className="mt-8">
        <div className="wrapper m-auto overflow-hidden">
            <ReactPlayer
                className="player"
                light={image.url}
                controls={true}
                width="100%"
                height="100%"
                playIcon={
                    <div className="flex items-center">
                        <PlayButton>
                            <FaPlay />
                        </PlayButton>
                    </div>
                }
                url={videoURL}
            />
        </div>
        </div>
    );
}

export default VideoPlayer;