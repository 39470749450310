import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import VideoPlayer from "./VideoPlayer";
import TextField from "./TextField";
import ImageSlider from "./ImageSlider";
import ProjectHeader from "./ProjectHeader";
import Image from "./Image";
import data from "../assets/data";

const DisposalApp = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    AOS.init({
      // once: true,
    });
  });

  const project = data.projects[3];
  const images = project.images;
  const title = project.title;
  const text = project.text;
  const videoURL = project.videoURL;
  const companyLogo = project.companyLogo;
  const partnerLogo = project.partnerLogo;
  const tags = project.tags;

  return (
    <div>
      <div data-aos="fade-down" data-aos-duration="500">
        <ProjectHeader title={title} companyLogo={companyLogo} partnerLogo={partnerLogo} tags={tags} />
      </div>
      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" className="content-wrapper">

        {/* Aufgabenstellung */}
        <TextField hasMarginTop={true} heading={text[0].heading} description={text[0].body} />
        <VideoPlayer images={images[8]} videoURL={videoURL} />

        {/* Problem Definition */}
        <TextField hasMarginTop={true} heading={text[1].heading} description={text[1].body} />
        <ImageSlider images={images.slice(1, 3)} />

        {/* Prototyp */}
        <TextField hasMarginTop={true} heading={text[2].heading} description={text[2].body} />
        <ImageSlider images={images.slice(3, 5)} />

        {/* Gamification */}
        <TextField hasMarginTop={true} heading={text[3].heading} description={text[3].body} />
        <Image image={images[5]} />

        {/* Machine Learning & Object recognition */}
        <TextField hasMarginTop={true} heading={text[4].heading} description={text[4].body} />

        {/* Visualisierung der KI */}
        <TextField hasMarginTop={true} heading={text[5].heading} description={text[5].body} />
        <Image image={images[6]} />

        {/* User Testing / Evaluation */}
        <TextField hasMarginTop={true} heading={text[6].heading} description={text[6].body} />
        <Image image={images[7]} />

      </div>
    </div>
  );
}

export default DisposalApp;