import React from "react";
import TextField from "./TextField";
import Divider from "./Divider";

function About({ title, description }) {

  return (
    <div className="flex flex-col md:flex-row justify-between">
      <h1 className="text-gradient text-3xl md:text-4xl md:leading-normal text-left">{title}</h1>
      <div className="">
        <TextField heading={"Über mich"} description={description}/>
      </div>
    </div>
  );
}

export default About;