import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import VideoPlayer from "./VideoPlayer";
import TextField from "./TextField";
import ImageSlider from "./ImageSlider";
import ProjectHeader from "./ProjectHeader";
import Image from "./Image";
import data from "../assets/data";

const VVSChatbot = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    AOS.init({
      // once: true,
    });
  });

  const project = data.projects[11];
  const images = project.images;
  const title = project.title;
  const text = project.text;
  const videoURL = project.videoURL;
  const companyLogo = project.companyLogo;
  const partnerLogo = project.partnerLogo;
  const tags = project.tags;

  return (
    <div className="">
      <div data-aos="fade-down" data-aos-duration="500">
        <ProjectHeader title={title} companyLogo={companyLogo} partnerLogo={partnerLogo} tags={tags} />
      </div>
      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" className="content-wrapper">
        {/* Usability Anlayse */}
        <TextField hasMarginTop={true} heading={text[0].heading} description={text[0].body} link={"https://www.vvs.de/redaktionelle-seiten-vvsde/themen-presse/vvs-news-der-neue-vvs-chatbot/"} linkText={"VVS-News: Der neue VVS Chatbot"} />
        <VideoPlayer images={images[7]} videoURL={videoURL} />

        {/* Zielgruppe */}
        <TextField hasMarginTop={true}  heading={text[1].heading} description={text[1].body} />
        <div className="md:grid md:grid-cols-2 md:gap-8 md:max-w-5xl m-auto">
          <Image image={images[1]} />
          <Image image={images[2]} />
        </div>

        {/* Studie */}
        <TextField hasMarginTop={true} heading={text[2].heading} description={text[2].body} />
        <ImageSlider images={images.slice(3, 7)} />

        {/* Ergebnisse */}
        <TextField hasMarginTop={true} heading={text[3].heading} description={text[3].body} />
      </div>
    </div>
  );
}

export default VVSChatbot;